import * as yup from 'yup';
import { TFunction } from 'i18next';

import { UB04_FIELDS } from './fields';
import { Rule } from 'antd/lib/form';
import { StoreValue } from 'antd/lib/form/interface';
import {
  testBillingAddress,
  testPayToAddress,
  testTypeOfBill3Char,
  testTypeOfBill4Char,
  testFutureDate,
  testEarlierCoversPeriodFrom,
  testEarlierPatientDOB,
  testEarlierPatientDOBById,
  testLaterThanOccurence35SpanDate,
  testLaterThanOccurence35BSpanDate,
  testLaterThanOccurence36ASpanDate,
  testLaterThanOccurence36BSpanDate,
  testLaterThanServiceDate,
  fromDataConditionedByBox50Fields,
  testPatientAddress,
  testTypeOfBillTOB004,
  testResponsiblePartyAddress,
  testNdcCode,
  testOtherProvider1LastName,
  testOtherProvider1Npi,
  testOperatingProviderNpi,
  testOtherProvider2Npi,
  testOtherProvider2LastName,
  testAttendingProviderLastName,
  // testAttendingProviderNameAndIdentifier,
  // testAttendingProviderNameAndNpi,
  testProviderNameNpiAndIdentifier,
  testOperatingProviderNameAndIdentifier,
  testOperatingProviderNameAndNpi,
  testAttendingProviderNpi,
  testOperatingProviderLastName,
  testOperatingProviderNpiFilled,
  testCodeQualifier,
  testICDValueZeroMin,
  testICDValueZeroMax,
  testICDValueNineMin,
  testICDValueNineMax,
  testInsured58bFirstName,
  testInsured58bLastName,
  testInsured58cFirstName,
  testInsured58cLastName,
  testValueAmountCode,
  testDocumentControlNumber64aRequired,
  testNonCoveredChargesLowerThanTotal,
  testTotalChargesSum,
  testOcurrenceSpanCode,
  testOcurrenceSpanEarlierFrom,
  testProcedureCode,
  testLowerEqual0,
  testTypeOfBillTOB005,
  testAttendingProviderId,
  testAttendingProviderQual,
  testOperatingProviderId,
  testOperatingProviderQual,
  testOtherProvider1Id,
  testOtherProvider1Qual,
  testOtherProvider2Id,
  testOtherProvider2Qual,
  testPayer,
  testTypeOfBillTOB006,
  testBillingNpi,
  testModifiersToCPT1,
  testModifiersToCPT2,
  testModifiersToCPT3,
  testModifiersToCPT4,
  testOcurrenceSpanEqualFrom,
  testOperatingProviderIsEmpty,
  revenueCodeLookup,
  occuranceCodeLookup,
  testValueCodeLookup,
  testConditionCodeLookup,
  testProcedureCodeLookup,
  testRequiredOccurrenceDate,
  testRequiredPayerPopulated,
  testOccurrenceSpanCode,
  testOtherTypeValue,
  // testPayerRegexCheck,
  testOtherPayer,
  testIsOccurenceCodeEmpty,
  testTypeOfBillMUST001,
  testRequiredLenghByLob,
  testRequiredRegexAlphanumByLob,
  testRequiredRegexNumericByLob,
  testIsOccurenceSpanEmpty,
  testNdcUnit,
  testIsNumberIfAlphaNum,
  testIsNumberIfNum,
  testIsBeforeFromServiceDate,
  testIsAfterToServiceDate,
  testOtherProvider1FirstName,
  testOtherProvider2FirstName,
  getDestinationPayer,
  testDocumentControlNumber64aPattern,
  testDocumentControlNumber64bPattern,
  testDocumentControlNumber64cPattern,
  testAfterThanServiceDate,
  testAdmissionDateToBill4Required,
  testAdmissionDateToBill4FromTo,
  testPhysicalAddress,
  testUniqueIdIfLobIw
} from './customRules';
import { commonZipcodeValidation, dateInvalidMsg, diagnoseCodeLookupMsg } from 'utils/hcfa/validations';
import { diagnosisCodeLookup, testIsDateValid, testNPIforLuhnAlghoritm } from 'utils/hcfa/customRules';
import { getUb04WarningSchema, getUb04WarningServiceTableShape, knownWarnings } from './warningSchema';
import { getFormProvidersDataFromGlobalObject } from 'utils/hcfa/putFormProvidersDataInGlobalObject';

const alphaSpecChar = /^[A-Za-z !@#$%^&*()_+{}\[\]:;<>,.?~\\/-]+$/;
const alphaNumSpecChar = /^[A-Za-z0-9 !@#$%^&*()_+{}\[\]:;<>,.?~\\/'"`´-]+$/;
const alphaNumSpecCharTextarea = /^[A-Za-z0-9\n !@#$%^&*()_+{}\[\]:;<>,.?~\\/'"`´-]+$/;

const alphaNum = /^[a-zA-Z0-9]+$/;
const alphaNumWithDec = /^[a-zA-Z0-9]+(?:\.\d+)?$/;
const startsWithVWXY = /^[VWXY]/;
const alpha = /^[A-Za-z]+$/;
const numericDecimal = /^\d+(\.\d+)?$/;
const numeric = /^[0-9]+$/;
const alphaNumWithDecimal = /^[A-Z]\d{2}[A-Z0-9]{0,4}$/;
const hour = /^([0-1]?[0-9]|2[0-3])$/;
const alphaNumWithSpace = /^[a-zA-Z0-9\s]+$/;

const alphaSpecCharVal = '[A-Z spec char]';
const alphaNumSpecCharVal = '[A-Z 0-9 spec char]';
const alphaNumVal = '[A-Z and 0-9]';
const alphaNumWithDecVal = '[A-Z 0-9 with decimals';
const startsWithVWXYVal = 'must start with WXYV';
const alphaVal = '[A-Z]';
const alphaSpaceVal = '[A-Z]';
const numericDecimalVal = '[0-1 with decimals]';
const numericVal = '[0-9]';
const alphaNumWithDecimalVal = '[A-Z 0-9 spec char]';
const hourVal = 'must be a number between 00 and 23';
const alphaNumWithSpaceVal = '[A-Z] and [0-9]';
const alphaNumStartsEndsWithNumber = /^\d.*\d$/;
const alphaNumStartsEndsWithNumberVal = 'that starts and ends with a number and contains only leters and numbers';
const poaRegex = /^[YNUW1]$/;
const poaVal = '[Y, N, U, W, 1]';

const commonNameValidation = (t: TFunction) =>
  yup
    .string()
    .nullable()
    .transform((v, o) => (o === '' ? null : v))
    .matches(alphaSpecChar, t('common:fieldErrors.VALID001', { validation: alphaSpecCharVal }))
    .min(1, t('common:fieldErrors.LEN001', { length: '(1-60)' }))
    .max(60, t('common:fieldErrors.LEN001', { length: '(1-60)' }));
const commonFirstNameValidation = (t: TFunction) =>
  yup
    .string()
    .nullable()
    .transform((v, o) => (o === '' ? null : v))
    .matches(alphaSpecChar, t('common:fieldErrors.VALID001', { validation: alphaSpecCharVal }))
    .min(1, t('common:fieldErrors.LEN001', { length: '(1-35)' }))
    .max(35, t('common:fieldErrors.LEN001', { length: '(1-35)' }));

const commonAddressValidation = (t: TFunction, maxLength: number) =>
  yup
    .string()
    .nullable()
    .transform((v, o) => (o === '' ? null : v))
    .matches(alphaNumSpecChar, t('common:fieldErrors.VALID001', { validation: alphaNumSpecCharVal }))
    .min(1, t('common:fieldErrors.LEN001', { length: `(1-${maxLength})` }))
    .max(maxLength, t('common:fieldErrors.LEN001', { length: `(1-${maxLength})` }));

const commonCityValidation = (t: TFunction, maxLength: number) =>
  yup
    .string()
    .nullable()
    .transform((v, o) => (o === '' ? null : v))
    .matches(alphaSpecChar, t('common:fieldErrors.VALID001', { validation: alphaSpecCharVal }))
    .min(1, t('common:fieldErrors.LEN001', { length: `(1-${maxLength})` }))
    .max(maxLength, t('common:fieldErrors.LEN001', { length: `(1-${maxLength})` }));

const getDiagnosisCodes = (t: TFunction) =>
  ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q'].reduce((map, letter) => {
    const codesMap: any = { ...map };

    const keyDx = `code67${letter}Dx`;
    if (letter === 'a') {
      codesMap[UB04_FIELDS[keyDx]] = yup
        .string()
        .nullable()
        .transform((v, o) => (o === '' ? null : v))
        .matches(alphaNumWithDec, t('common:fieldErrors.VALID001', { validation: alphaNumWithDecVal }))
        .min(3, t('common:fieldErrors.LEN001', { length: `(3-7)` }))
        .max(7, t('common:fieldErrors.LEN001', { length: `(3-7)` }));
      // .test('custom-code671', t('common:fieldErrors.LEN001', { length: '(3-7)' }), testICDValueZeroMin)
      // .test('custom-code672', t('common:fieldErrors.LEN001', { length: '(3-7)' }), testICDValueZeroMax)
      // .test('custom-code673', t('common:fieldErrors.ICD001'), testICDValueNineMin);
      // .test('custom-code674', t('common:fieldErrors.ICD001'), testICDValueNineMax);
      // .test('dxCode-lookup', diagnoseCodeLookupMsg, diagnosisCodeLookup);
    } else {
      codesMap[UB04_FIELDS[keyDx]] = yup
        .string()
        .nullable()
        .transform((v, o) => (o === '' ? null : v))
        .matches(alphaNumWithDec, t('common:fieldErrors.VALID001', { validation: alphaNumWithDecVal }))
        .min(3, t('common:fieldErrors.LEN001', { length: `(3-7)` }))
        .max(7, t('common:fieldErrors.LEN001', { length: `(3-7)` }));
      // .test('custom-code671', t('common:fieldErrors.LEN001', { length: '(3-7)' }), testICDValueZeroMin)
      // .test('custom-code672', t('common:fieldErrors.LEN001', { length: '(3-7)' }), testICDValueZeroMax)
      // .test('custom-code673', t('common:fieldErrors.ICD001'), testICDValueNineMin);
      // .test('custom-code674', t('common:fieldErrors.ICD001'), testICDValueNineMax);
      // .test('dxCode-lookup', diagnoseCodeLookupMsg, diagnosisCodeLookup);
    }

    const keyPoa = `code67${letter}Poa`;
    codesMap[UB04_FIELDS[keyPoa]] = poaValidationFixLeng(t, 1, poaRegex, poaVal);

    return codesMap;
  }, {});

const conditionCodeValidation = (t: any) =>
  yup
    .string()
    .nullable()
    .transform((v, o) => (o === '' ? null : v))
    .matches(alphaNum, t('common:fieldErrors.VALID001', { validation: alphaNumVal }))
    .min(2, t('common:fieldErrors.LEN001', { length: '(2)' }))
    .max(2, t('common:fieldErrors.LEN001', { length: '(2)' }));

const occurrenceDateValidation = (t: any) =>
  yup
    .string()
    .nullable()
    .transform((v, o) => (o === '' ? null : v));

const valueCodesValidation = (t: any) =>
  yup
    .string()
    .nullable()
    .transform((v, o) => (o === '' ? null : v))
    .matches(alphaNum, t('common:fieldErrors.VALID001', { validation: alphaNumVal }))
    .min(2, t('common:fieldErrors.LEN001', { length: '(2)' }))
    .max(2, t('common:fieldErrors.LEN001', { length: '(2)' }));

const valueAmountValidation = (t: any, min: number, max: number) =>
  yup
    .string()
    .nullable()
    .transform((v, o) => (o === '' ? null : v))
    .matches(numericDecimal, t('common:fieldErrors.VALID001', { validation: numericDecimalVal }))
    .min(min, t('common:fieldErrors.LEN001', { length: `(${min}-${max})` }))
    .max(max, t('common:fieldErrors.LEN001', { length: `(${min}-${max})` }));

const payer50 = (t: any) =>
  yup
    .string()
    .nullable()
    .transform((v, o) => (o === '' ? null : v))
    .matches(alphaNumWithSpace, t('common:fieldErrors.VALID001', { validation: alphaNumWithSpaceVal }))
    .min(1, t('common:fieldErrors.LEN001', { length: '(1-60)' }))
    .max(60, t('common:fieldErrors.LEN001', { length: '(1-60)' }));

const otherId57 = (t: any) =>
  yup
    .string()
    .nullable()
    .transform((v, o) => (o === '' ? null : v))
    .matches(alphaNum, t('common:fieldErrors.VALID001', { validation: alphaNumVal }))
    .min(1, t('common:fieldErrors.LEN001', { length: '(1-15)' }))
    .max(15, t('common:fieldErrors.LEN001', { length: '(1-15)' }));

const genericValidation = (t: any, min: number, max: number, validationType: any, validationText: string) =>
  yup
    .string()
    .nullable()
    .transform((v, o) => (o === '' ? null : v))
    .matches(validationType, t('common:fieldErrors.VALID001', { validation: validationText }))
    .min(min, t('common:fieldErrors.LEN001', { length: `(${min}-${max})` }))
    .max(max, t('common:fieldErrors.LEN001', { length: `(${min}-${max})` }));

const genericValidationFixLeng = (t: any, length: number, validationType: any, validationText: string) =>
  yup
    .string()
    .nullable()
    .transform((v, o) => (o === '' ? null : v))
    .matches(validationType, t('common:fieldErrors.VALID001', { validation: validationText }))
    .min(length, t('common:fieldErrors.LEN001', { length: `(${length}-${length})` }))
    .max(length, t('common:fieldErrors.LEN001', { length: `(${length}-${length})` }));
const poaValidationFixLeng = (t: any, length: number, validationType: any, validationText: string) =>
  yup
    .string()
    .nullable()
    .transform((v, o) => (o === '' ? null : v))
    .max(length, t('common:fieldErrors.LEN001', { length: `(${length})` }))
    .matches(validationType, t('common:fieldErrors.VALID001', { validation: validationText }));

const getUb04ServiceTableShape = (t: TFunction) =>
  yup.object().shape({
    [UB04_FIELDS.rev_cd_42]: genericValidationFixLeng(t, 4, numeric, numericVal).required(
      t('common:fieldErrors.MUST001')
    ),
    // .test(
    //   'lookup-rev_cd_42',
    //   'Please review the entered value, it is not found in the standard list of values',
    //   revenueCodeLookup
    // ),
    [UB04_FIELDS.description_43]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .min(0, t('common:fieldErrors.LEN001', { length: '(0-80)' }))
      .max(80, t('common:fieldErrors.LEN001', { length: '(0-80)' })),
    [UB04_FIELDS.modifier_1]: genericValidationFixLeng(t, 2, alphaNum, alphaNumVal).test(
      'custom-.modifier_1',
      'Duplicate Modifier, please check value',
      testModifiersToCPT1
    ),
    [UB04_FIELDS.modifier_2]: genericValidationFixLeng(t, 2, alphaNum, alphaNumVal).test(
      'custom-.modifier_2',
      'Duplicate Modifier, please check value',
      testModifiersToCPT2
    ),
    [UB04_FIELDS.modifier_3]: genericValidationFixLeng(t, 2, alphaNum, alphaNumVal).test(
      'custom-.modifier_3',
      'Duplicate Modifier, please check value',
      testModifiersToCPT3
    ),
    [UB04_FIELDS.modifier_4]: genericValidationFixLeng(t, 2, alphaNum, alphaNumVal).test(
      'custom-.modifier_4',
      'Duplicate Modifier, please check value',
      testModifiersToCPT4
    ),
    // [UB04_FIELDS.modifier_5]: genericValidation(t, 1, 2, alphaNum, alphaNumVal).test(
    //   'custom-.modifier_5',
    //   'Duplicate Modifier, please check value',
    //   testModifiersToCPT5
    // ),
    // [UB04_FIELDS.modifier_6]: genericValidation(t, 1, 2, alphaNum, alphaNumVal).test(
    //   'custom-.modifier_6',
    //   'Duplicate Modifier, please check value',
    //   testModifiersToCPT6
    // ),
    // [UB04_FIELDS.modifier_7]: genericValidation(t, 1, 2, alphaNum, alphaNumVal).test(
    //   'custom-.modifier_7',
    //   'Duplicate Modifier, please check value',
    //   testModifiersToCPT7
    // ),
    // [UB04_FIELDS.modifier_8]: genericValidation(t, 1, 2, alphaNum, alphaNumVal).test(
    //   'custom-.modifier_8',
    //   'Duplicate Modifier, please check value',
    //   testModifiersToCPT8
    // ),
    // [UB04_FIELDS.modifier_9]: genericValidation(t, 1, 2, alphaNum, alphaNumVal).test(
    //   'custom-.modifier_9',
    //   'Duplicate Modifier, please check value',
    //   testModifiersToCPT9
    // ),
    // [UB04_FIELDS.modifier_10]: genericValidation(t, 1, 2, alphaNum, alphaNumVal).test(
    //   'custom-.modifier_10',
    //   'Duplicate Modifier, please check value',
    //   testModifiersToCPT10
    // ),
    [UB04_FIELDS.desc_ndc_code]: genericValidationFixLeng(t, 11, numeric, numericVal),
    [UB04_FIELDS.desc_ndc_unit]: genericValidation(t, 1, 15, numericDecimal, numericDecimalVal).test(
      'custom-desc_ndc_unit',
      t('common:fieldErrors.COM003'),
      testNdcCode
    ),
    [UB04_FIELDS.hcpcs_rates_44]: genericValidation(t, 1, 6, alphaNum, alphaNumVal),
    // .test(
    //   'procedure-lookup44',
    //   'Please review the entered value, it is not found in the standard list of values',
    //   testProcedureCodeLookup
    // ),
    [UB04_FIELDS.hcpcs_description_44]: genericValidation(t, 1, 10, alphaNumSpecChar, alphaNumSpecCharVal),
    [UB04_FIELDS.desc_ndc_qty]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(alphaNumSpecChar, t('common:fieldErrors.descriptionAlfNumAndHyph'))
      .max(11, 'Field does not meet length requirement, please re-enter correct value')
      .test('custom-desc_ndc_qty', t('common:fieldErrors.COM003'), testNdcCode)
      .test('custom-desc_ndc_qty2', t('common:fieldErrors.COM005'), testNdcUnit),
    [UB04_FIELDS.dos_45]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .test('custom-dos_45future', t('common:fieldErrors.DATE001'), testFutureDate)
      .test(
        'custom-dos_45future2',
        'Date entered is earlier than Statement From Date, please re-enter correct value',
        testIsBeforeFromServiceDate
      )
      .test('custom-dos_45future2', 'Value cannot be later then Statement To Date', testIsAfterToServiceDate)

      .test('custom-dos_45EarlierPatientDOB', t('common:fieldErrors.DOS003'), testEarlierPatientDOB)
      .test('custom-validDate', dateInvalidMsg, testIsDateValid),
    [UB04_FIELDS.serv_units_46]: genericValidation(t, 1, 10, numericDecimal, numericDecimalVal)
      .required(t('common:fieldErrors.MUST001'))
      .test('custom-serviceUnits46', t('common:fieldErrors.VALID007'), testLowerEqual0),
    [UB04_FIELDS.total_charges_47]: valueAmountValidation(t, 1, 20).required(t('common:fieldErrors.MUST001')),
    [UB04_FIELDS.nonCoveredCharges_48]: valueAmountValidation(t, 1, 18),
    [UB04_FIELDS.ub49]: valueAmountValidation(t, 1, 10)
  });

export const getUb04Schema = (t: TFunction, values?: any) =>
  yup.object().shape({
    [UB04_FIELDS.billingNpi]: genericValidation(t, 10, 10, numeric, numericVal)
      .test('custom-billingNpi2', t('common:fieldErrors.NPI002'), testBillingNpi)
      .test('custom-npiluhn', 'NPI is not valid', testNPIforLuhnAlghoritm)
      .required(t('common:fieldErrors.MUST001')),
    [UB04_FIELDS.servicesList]: yup
      .array()
      .min(1, 'At least one item is required in the Service list')
      .required(t('common:fieldErrors.MUST001'))
      .of(getUb04ServiceTableShape(t)),
    [UB04_FIELDS.typeOfBill]: genericValidation(t, 3, 4, alphaNum, alphaNumVal)
      .required(t('common:fieldErrors.MUST001'))
      .test('custom-typeOfBill3Char', t('common:fieldErrors.TOB002'), testTypeOfBill3Char)
      .test('custom-typeOfBill4Char', t('common:fieldErrors.TOB003'), testTypeOfBill4Char),
    [UB04_FIELDS.admissionDate]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      // .required(t('common:fieldErrors.MUST001'))
      .test('custom-admissionDateRequired', t('common:fieldErrors.MUST001'), (value, context) =>
        testTypeOfBillMUST001(value, UB04_FIELDS.admissionDate, context)
      )
      .test('custom-admissionDateRequired', t('common:fieldErrors.TOB004'), testTypeOfBillTOB004)
      .test('custom-tobill4', t('common:fieldErrors.MUST001'), testAdmissionDateToBill4Required)
      // .test(
      //   'custom-tobill4smtpfromto',
      //   'Admission date must be within the 6 Statement from and Statement To Date',
      //   testAdmissionDateToBill4FromTo
      // )
      .test('custom-admissionDatefuture', t('common:fieldErrors.DATE001'), testFutureDate)
      .test('custom-validDate', dateInvalidMsg, testIsDateValid),
    [UB04_FIELDS.federalTaxNumber]: genericValidationFixLeng(t, 9, numeric, numericVal).required(
      t('common:fieldErrors.MUST001')
    ),
    [UB04_FIELDS.coversPeriodFrom]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .required(t('common:fieldErrors.MUST001'))
      .test('custom-coversPeriodFromfuture', t('common:fieldErrors.DATE001'), testFutureDate)

      .test('custom-coversPeriodFromEarlierPatientDOB', t('common:fieldErrors.DOS003'), (value, context) =>
        testEarlierPatientDOBById(value, context, UB04_FIELDS.coversPeriodFrom)
      )
      .test('custom-coversPeriodFromLaterServiceDate', t('common:fieldErrors.DOS005'), testLaterThanServiceDate)
      .test(
        'custom-fromDataConditionedByBox50Fields',
        'CareSource not listed as Payer Name',
        fromDataConditionedByBox50Fields
      )
      .test('custom-validDate', dateInvalidMsg, testIsDateValid),
    [UB04_FIELDS.coversPeriodTo]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .required(t('common:fieldErrors.MUST001'))
      .test('custom-coversPeriodTofuture', t('common:fieldErrors.DATE001'), testFutureDate)

      .test('custom-coversPeriodToEarlierCoversPeriodFrom', t('common:fieldErrors.DOS002'), testEarlierCoversPeriodFrom)
      .test('custom-coversPeriodToEarlierPatientDOB', t('common:fieldErrors.DOS003'), (value, context) =>
        testEarlierPatientDOBById(value, context, UB04_FIELDS.coversPeriodTo)
      )
      .test(
        'custom-coversPeriodToLaterServiceDate',
        'Value cannot be earlier than the Date of Service, please re-enter correct value',
        testAfterThanServiceDate
      )
      .test('custom-validDate', dateInvalidMsg, testIsDateValid),
    [UB04_FIELDS.admissionHour]: genericValidation(t, 2, 2, hour, hourVal)
      // .required(t('common:fieldErrors.MUST001'))
      .test('custom-admissionDateRequired', t('common:fieldErrors.TOB004'), (value, context) =>
        testTypeOfBillMUST001(value, UB04_FIELDS.admissionHour, context)
      )
      .test('custom-admissionHour', t('common:fieldErrors.TOB004'), testTypeOfBillTOB004),
    [UB04_FIELDS.admissionSource]: genericValidationFixLeng(t, 1, numeric, numericVal)
      .required(t('common:fieldErrors.MUST001'))
      .test('custom-admissionSourceRequired', t('common:fieldErrors.TOB005'), testTypeOfBillTOB005),
    [UB04_FIELDS.adminNecessaryDays]: genericValidation(t, 1, 5, numeric, numericVal),
    [UB04_FIELDS.payer_Name]: genericValidation(t, 1, 100, alphaNumSpecChar, alphaNumSpecCharVal),
    [UB04_FIELDS.billingName]: genericValidation(t, 1, 80, alphaNumSpecChar, alphaNumSpecCharVal)
      .required(t('common:fieldErrors.MUST001'))
      .min(1, t('common:fieldErrors.LEN001', { length: '(1)' }))
      .max(80, t('common:fieldErrors.LEN001', { length: '(80)' })),
    [UB04_FIELDS.billingAddress]: commonAddressValidation(t, 80)
      .test('custom-billingAddressPOBox', 'Physical Address Required for Billing Provider', testPhysicalAddress)
      .required(t('common:fieldErrors.MUST001')),
    [UB04_FIELDS.billingCity]: commonCityValidation(t, 80)
      .test('custom-billingCity', t('common:fieldErrors.ADD001'), testBillingAddress)
      .required(t('common:fieldErrors.MUST001')),
    [UB04_FIELDS.billingState]: yup
      .string()
      .nullable()
      .test('custom-billingState', t('common:fieldErrors.ADD001'), testBillingAddress)
      .required(t('common:fieldErrors.MUST001')),
    [UB04_FIELDS.billingZip]: commonZipcodeValidation(t)
      .test('custom-billinZip', t('common:fieldErrors.ADD001'), testBillingAddress)
      .required(t('common:fieldErrors.MUST001')),
    [UB04_FIELDS.billingPhone]: genericValidationFixLeng(t, 10, numeric, numericVal),
    [UB04_FIELDS.payToName]: genericValidation(t, 1, 80, alphaNumSpecChar, alphaNumSpecCharVal),
    [UB04_FIELDS.payToAddress]: genericValidation(t, 1, 80, alphaNumSpecChar, alphaNumSpecCharVal),
    [UB04_FIELDS.payToCity]: genericValidation(t, 1, 80, alphaSpecChar, alphaSpecCharVal).test(
      'custom-payToCity',
      t('common:fieldErrors.ADD001'),
      testPayToAddress
    ),
    [UB04_FIELDS.payToState]: yup
      .string()
      .nullable()
      .test('custom-payToState', t('common:fieldErrors.ADD001'), testPayToAddress),
    [UB04_FIELDS.payToZip]: commonZipcodeValidation(t).test(
      'custom-payToZip',
      t('common:fieldErrors.ADD001'),
      testPayToAddress
    ),
    [UB04_FIELDS.payToPhone]: genericValidationFixLeng(t, 10, numeric, numericVal),

    [UB04_FIELDS.attendingProviderQual]: genericValidationFixLeng(t, 2, alphaNum, alphaNumVal).test(
      'custom-attendingProviderQual',
      t('common:fieldErrors.NPI006'),
      testAttendingProviderId
    ),
    [UB04_FIELDS.attendingProviderId]: genericValidation(t, 1, 15, alphaNum, alphaNumVal)
      .test('custom-attendingProviderId', t('common:fieldErrors.NPI005'), testAttendingProviderQual)
      .test('custom-testProviderNameNpiAndIdentifier', t('Provider NPI or ID cannot be blank.'), (value, context) =>
        testProviderNameNpiAndIdentifier(
          value,
          context,
          UB04_FIELDS.attendingProviderLastName,
          UB04_FIELDS.attendingProviderFirstName,
          UB04_FIELDS.attendingProviderId,
          UB04_FIELDS.attendingProviderNpi
        )
      ),
    [UB04_FIELDS.attendingProviderNpi]: genericValidationFixLeng(t, 10, numeric, numericVal)
      .test('custom-npiluhn', 'NPI is not valid', testNPIforLuhnAlghoritm)
      .test('custom-testProviderNameNpiAndIdentifier', t('Provider NPI or ID cannot be blank.'), (value, context) =>
        testProviderNameNpiAndIdentifier(
          value,
          context,
          UB04_FIELDS.attendingProviderLastName,
          UB04_FIELDS.attendingProviderFirstName,
          UB04_FIELDS.attendingProviderId,
          UB04_FIELDS.attendingProviderNpi
        )
      ),
    [UB04_FIELDS.attendingProviderLastName]: genericValidation(t, 1, 60, alphaSpecChar, alphaSpecCharVal)
      .test('custom-attendingProviderLastName', t('common:fieldErrors.NPI001'), testAttendingProviderNpi)
      .test('custom-attendingProviderQual', t('common:fieldErrors.NPI008'), testAttendingProviderId)
      .test('custom-attendingProviderId', t('common:fieldErrors.NPI008'), testAttendingProviderQual),
    [UB04_FIELDS.attendingProviderFirstName]: genericValidation(t, 1, 60, alphaSpecChar, alphaSpecCharVal)
      .test('custom-attendingProviderLastName', t('common:fieldErrors.NPI009'), testAttendingProviderNpi)
      .test('custom-attendingProviderQual', t('common:fieldErrors.NPI009'), testAttendingProviderId)
      .test('custom-attendingProviderId', t('common:fieldErrors.NPI009'), testAttendingProviderQual),
    [UB04_FIELDS.attendingProviderGender]: yup.string().nullable(),

    [UB04_FIELDS.attendingProviderMiddleInitial]: genericValidation(t, 1, 9, alpha, alphaVal),
    [UB04_FIELDS.operatingProviderMiddleInitial]: genericValidation(t, 1, 9, alpha, alphaVal),
    [UB04_FIELDS.otherProvider1MiddleInitial]: genericValidation(t, 1, 9, alpha, alphaVal),
    [UB04_FIELDS.otherProvider2MiddleInitial]: genericValidation(t, 1, 9, alpha, alphaVal),

    [UB04_FIELDS.operatingProviderQual]: genericValidationFixLeng(t, 2, alphaNum, alphaNumVal).test(
      'custom-operatingProviderQual',
      t('common:fieldErrors.NPI006'),
      testOperatingProviderId
    ),
    [UB04_FIELDS.operatingProviderId]: genericValidation(t, 1, 15, alphaNum, alphaNumVal)
      .test('custom-operatingProviderId', t('common:fieldErrors.NPI005'), testOperatingProviderQual)
      .test('custom-testProviderNameNpiAndIdentifier', t('Provider NPI or ID cannot be blank.'), (value, context) =>
        testProviderNameNpiAndIdentifier(
          value,
          context,
          UB04_FIELDS.operatingProviderLastName,
          UB04_FIELDS.operatingProviderFirstName,
          UB04_FIELDS.operatingProviderId,
          UB04_FIELDS.operatingProviderNpi
        )
      ),
    [UB04_FIELDS.operatingProviderNpi]: genericValidationFixLeng(t, 10, numeric, numericVal)
      //.test('custom-operatingProviderNpi', t('common:fieldErrors.NPI002'), testOperatingProviderLastName)
      .test('custom-npiluhn', 'NPI is not valid', testNPIforLuhnAlghoritm)
      .test('custom-testProviderNameNpiAndIdentifier', t('Provider NPI or ID cannot be blank.'), (value, context) =>
        testProviderNameNpiAndIdentifier(
          value,
          context,
          UB04_FIELDS.operatingProviderLastName,
          UB04_FIELDS.operatingProviderFirstName,
          UB04_FIELDS.operatingProviderId,
          UB04_FIELDS.operatingProviderNpi
        )
      )
      .test('custom-operatingProviderNpiBlank', t('common:fieldErrors.NPI007'), (value) =>
        testOperatingProviderIsEmpty(
          value,
          [values?.[UB04_FIELDS.otherProvider1Npi], values?.[UB04_FIELDS.otherProvider2Npi]],
          [values?.[UB04_FIELDS.otherType78], values?.[UB04_FIELDS.otherType79]]
        )
      ),
    [UB04_FIELDS.operatingProviderLastName]: genericValidation(t, 1, 35, alphaSpecChar, alphaSpecCharVal)
      .test('custom-operatingProviderLastName', t('common:fieldErrors.NPI001'), testOperatingProviderNpi)
      .test('custom-operatingProviderQual', t('common:fieldErrors.NPI008'), testOperatingProviderId)
      .test('custom-operatingProviderId', t('common:fieldErrors.NPI008'), testOperatingProviderQual)
      .test('custom-operatingProviderLastNameBlank', t('common:fieldErrors.NPI007'), (value) =>
        testOperatingProviderIsEmpty(
          value,
          [values?.[UB04_FIELDS.otherProvider1LastName], values?.[UB04_FIELDS.otherProvider2LastName]],
          [values?.[UB04_FIELDS.otherType78], values?.[UB04_FIELDS.otherType79]]
        )
      ),
    [UB04_FIELDS.operatingProviderFirstName]: genericValidation(t, 1, 25, alphaSpecChar, alphaSpecCharVal)
      .test('custom-operatingProviderLastName', t('common:fieldErrors.NPI009'), testOperatingProviderNpi)
      .test('custom-operatingProviderQual', t('common:fieldErrors.NPI009'), testOperatingProviderId)
      .test('custom-operatingProviderId', t('common:fieldErrors.NPI009'), testOperatingProviderQual)
      .test('custom-operatingProviderFirstNameBlank', t('common:fieldErrors.NPI007'), (value) =>
        testOperatingProviderIsEmpty(
          value,
          [values?.[UB04_FIELDS.otherProvider1FirstName], values?.[UB04_FIELDS.otherProvider2FirstName]],
          [values?.[UB04_FIELDS.otherType78], values?.[UB04_FIELDS.otherType79]]
        )
      ),
    [UB04_FIELDS.operatingProviderGender]: yup.string().nullable(),

    [UB04_FIELDS.otherProvider1Qual]: genericValidationFixLeng(t, 2, alphaNum, alphaNumVal).test(
      'custom-otherProvider1Qual',
      t('common:fieldErrors.NPI006'),
      testOtherProvider1Id
    ),
    [UB04_FIELDS.otherProvider1Id]: genericValidation(t, 1, 15, alphaNum, alphaNumVal)
      .test('custom-otherProvider1Id', t('common:fieldErrors.NPI005'), testOtherProvider1Qual)
      .test('custom-testProviderNameNpiAndIdentifier', t('Provider NPI or ID cannot be blank.'), (value, context) =>
        testProviderNameNpiAndIdentifier(
          value,
          context,
          UB04_FIELDS.otherProvider1LastName,
          UB04_FIELDS.otherProvider1FirstName,
          UB04_FIELDS.otherProvider1Id,
          UB04_FIELDS.otherProvider1Npi
        )
      ),
    [UB04_FIELDS.otherProvider1Npi]: genericValidationFixLeng(t, 10, numeric, numericVal)
      //.test('custom-otherProvider1Npi', t('common:fieldErrors.NPI002'), testOtherProvider1LastName)
      .test('custom-npiluhn', 'NPI is not valid', testNPIforLuhnAlghoritm)
      .test('custom-operatingProvider1Npi', t('common:fieldErrors.NPI007'), (value) =>
        testOperatingProviderIsEmpty(
          value,
          [values?.[UB04_FIELDS.otherProvider2Npi]],
          [values?.[UB04_FIELDS.otherType79]]
        )
      )
      .test('custom-testProviderNameNpiAndIdentifier', t('Provider NPI or ID cannot be blank.'), (value, context) =>
        testProviderNameNpiAndIdentifier(
          value,
          context,
          UB04_FIELDS.otherProvider1LastName,
          UB04_FIELDS.otherProvider1FirstName,
          UB04_FIELDS.otherProvider1Id,
          UB04_FIELDS.otherProvider1Npi
        )
      ),
    [UB04_FIELDS.otherProvider1LastName]: commonNameValidation(t)
      .test('custom-otherProvider1LastNameNpi', t('common:fieldErrors.NPI001'), testOtherProvider1Npi)
      .test('custom-operatingProviderQual', t('common:fieldErrors.NPI008'), testOtherProvider1Id)
      .test('custom-otherProvider1FirstName', t('common:fieldErrors.NAME001'), testOtherProvider1FirstName)
      .test('custom-operatingProviderId', t('common:fieldErrors.NPI008'), testOtherProvider1Qual)
      .test('custom-operatingProvider1LastName', t('common:fieldErrors.NPI007'), (value) =>
        testOperatingProviderIsEmpty(
          value,
          [values?.[UB04_FIELDS.otherProvider2LastName]],
          [values?.[UB04_FIELDS.otherType79]]
        )
      ),
    [UB04_FIELDS.otherProvider1FirstName]: commonFirstNameValidation(t)
      .test('custom-otherProvider1LastNameNpi', t('common:fieldErrors.NPI009'), testOtherProvider1Npi)
      .test('custom-operatingProviderQual', t('common:fieldErrors.NPI009'), testOtherProvider1Id)
      .test('custom-operatingProviderId', t('common:fieldErrors.NPI009'), testOtherProvider1Qual)
      .test('custom-otherProvider1FirstName', t('common:fieldErrors.NAME001'), testOtherProvider1LastName)
      .test('custom-operatingProvider1FirstName', t('common:fieldErrors.NPI007'), (value) =>
        testOperatingProviderIsEmpty(
          value,
          [values?.[UB04_FIELDS.otherProvider2FirstName]],
          [values?.[UB04_FIELDS.otherType79]]
        )
      ),
    [UB04_FIELDS.otherProvider1Gender]: yup.string().nullable(),

    [UB04_FIELDS.otherProvider2Qual]: genericValidationFixLeng(t, 2, alphaNum, alphaNumVal).test(
      'custom-otherProvider2Qual',
      t('common:fieldErrors.NPI006'),
      testOtherProvider2Id
    ),
    [UB04_FIELDS.otherProvider2Id]: genericValidation(t, 1, 15, alphaNum, alphaNumVal)
      .test('custom-otherProvider2Id', t('common:fieldErrors.NPI005'), testOtherProvider2Qual)
      .test('custom-testProviderNameNpiAndIdentifier', t('Provider NPI or ID cannot be blank.'), (value, context) =>
        testProviderNameNpiAndIdentifier(
          value,
          context,
          UB04_FIELDS.otherProvider2LastName,
          UB04_FIELDS.otherProvider2FirstName,
          UB04_FIELDS.otherProvider2Id,
          UB04_FIELDS.otherProvider2Npi
        )
      ),
    [UB04_FIELDS.otherProvider2Npi]: genericValidationFixLeng(t, 10, numeric, numericVal)
      .test('custom-npiluhn', 'NPI is not valid', testNPIforLuhnAlghoritm)
      .test('custom-testProviderNameNpiAndIdentifier', t('Provider NPI or ID cannot be blank.'), (value, context) =>
        testProviderNameNpiAndIdentifier(
          value,
          context,
          UB04_FIELDS.otherProvider2LastName,
          UB04_FIELDS.otherProvider2FirstName,
          UB04_FIELDS.otherProvider2Id,
          UB04_FIELDS.otherProvider2Npi
        )
      ),
    //.test('custom-otherProvider2Npi', t('common:fieldErrors.NPI002'), testOtherProvider2LastName),
    [UB04_FIELDS.otherProvider2LastName]: commonNameValidation(t)
      .test('custom-otherProvider2LastNameNpi', t('common:fieldErrors.NPI001'), testOtherProvider2Npi)
      .test('custom-operatingProviderQual', t('common:fieldErrors.NPI008'), testOtherProvider2Id)
      .test('custom-operatingProviderId', t('common:fieldErrors.NPI008'), testOtherProvider2Qual)
      .test('custom-otherProvider2FirstName', t('common:fieldErrors.NAME001'), testOtherProvider2FirstName),
    [UB04_FIELDS.otherProvider2FirstName]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(alphaSpecChar, t('common:fieldErrors.VALID001', { validation: alphaSpecCharVal }))
      .min(1, t('common:fieldErrors.LEN001', { length: '(1-60)' }))
      .max(35, t('common:fieldErrors.LEN001', { length: '(1-60)' }))
      .test('custom-otherProvider2LastNameNpi', t('common:fieldErrors.NPI009'), testOtherProvider2Npi)
      .test('custom-operatingProviderQual', t('common:fieldErrors.NPI009'), testOtherProvider2Id)
      .test('custom-operatingProviderId', t('common:fieldErrors.NPI009'), testOtherProvider2Qual)
      .test('custom-otherProvider2FirstName', t('common:fieldErrors.NAME001'), testOtherProvider2LastName),
    [UB04_FIELDS.otherProvider2Gender]: yup.string().nullable(),

    [UB04_FIELDS.otherType78]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .min(0, t('common:fieldErrors.LEN001', { length: '(0-2)' }))
      .max(2, t('common:fieldErrors.LEN001', { length: '(0-2)' })),
    // .test('otherType78', t('common:fieldErrors.OtherPhysician'), testOtherTypeValue),
    [UB04_FIELDS.otherType79]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .min(0, t('common:fieldErrors.LEN001', { length: '(0-2)' }))
      .max(2, t('common:fieldErrors.LEN001', { length: '(0-2)' })),
    // .test('otherType79', t('common:fieldErrors.OtherPhysician'), testOtherTypeValue),

    [UB04_FIELDS.medicalRecordNumber]: genericValidation(t, 1, 30, alphaNumSpecChar, alphaNumSpecCharVal),
    [UB04_FIELDS.patientControlNumber]: genericValidation(t, 1, 24, alphaNumSpecChar, alphaNumSpecCharVal),
    [UB04_FIELDS.patientId]: genericValidation(t, 1, 19, alphaNumSpecChar, alphaNumSpecCharVal),
    [UB04_FIELDS.patientLastName]: genericValidation(t, 1, 60, alphaSpecChar, alphaSpecCharVal).required(
      t('common:fieldErrors.MUST001')
    ),
    [UB04_FIELDS.patientFirstName]: genericValidation(t, 1, 35, alphaSpecChar, alphaSpecCharVal).required(
      t('common:fieldErrors.MUST001')
    ),
    [UB04_FIELDS.patientMiddleInitial]: genericValidation(t, 1, 9, alpha, alphaVal),
    [UB04_FIELDS.patientGen]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v)),

    [UB04_FIELDS.pat_addr2]: genericValidation(t, 1, 55, alphaNumSpecChar, alphaNumSpecCharVal),
    [UB04_FIELDS.patientAddress]: genericValidation(t, 1, 55, alphaNumSpecChar, alphaNumSpecCharVal).required(
      t('common:fieldErrors.MUST001')
    ),
    [UB04_FIELDS.patientCity]: commonCityValidation(t, 30)
      .required(t('common:fieldErrors.MUST001'))
      .test('custom-patientCity', t('common:fieldErrors.ADD001'), testPatientAddress),
    [UB04_FIELDS.patientState]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .required(t('common:fieldErrors.MUST001'))
      .test('custom-patientState', t('common:fieldErrors.ADD001'), testPatientAddress),
    [UB04_FIELDS.patientZip]: commonZipcodeValidation(t)
      .required(t('common:fieldErrors.MUST001'))
      .test('custom-patientZip', t('common:fieldErrors.ADD001'), testPatientAddress),
    [UB04_FIELDS.patientBirthdate]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .required(t('common:fieldErrors.MUST001'))
      .test('custom-patientBirthdatefuture', t('common:fieldErrors.DATE001'), testFutureDate)

      .test('custom-validDate', dateInvalidMsg, testIsDateValid),
    [UB04_FIELDS.patientSex]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .required(t('common:fieldErrors.MUST001')),
    [UB04_FIELDS.admissionType]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(numeric, t('common:fieldErrors.VALID001', { validation: numericVal }))
      .min(1, t('common:fieldErrors.LEN001', { length: '(1)' }))
      .max(1, t('common:fieldErrors.LEN001', { length: '(1)' })),

    [UB04_FIELDS.dischargeHour]: genericValidation(t, 2, 2, hour, hourVal)
      // .required(t('common:fieldErrors.MUST001'))
      .test('custom-admissionDateRequired', t('common:fieldErrors.TOB004'), (value, context) =>
        testTypeOfBillMUST001(value, UB04_FIELDS.dischargeHour, context)
      ),
    // .test('custom-dischargeHour', t('common:fieldErrors.TOB004'), testTypeOfBillTOB004),
    [UB04_FIELDS.insured58aLastName]: yup
      .string()
      .nullable()
      .when([UB04_FIELDS.payer50A, UB04_FIELDS.payer50B, UB04_FIELDS.payer50C], {
        is: (payerA: string, payerB: string, payerC: string) => {
          if (getDestinationPayer(payerA, payerB, payerC) === 'A') return true;
          return false;
        },
        then: (schema) =>
          schema
            .transform((v, o) => (o === '' ? null : v))
            .required(t('common:fieldErrors.MUST001'))
            .matches(alphaSpecChar, t('common:fieldErrors.VALID001', { validation: alphaSpecCharVal }))
            .min(1, t('common:fieldErrors.LEN001', { length: `(1-60)` }))
            .max(60, t('common:fieldErrors.LEN001', { length: `(1-60)` }))
            .required(t('common:fieldErrors.MUST001'))
            .test('custom-insured58aLastNamePayer', t('common:fieldErrors.NAME001'), testPayer)
            .test('custom-insured58aLastNameRequired', t('common:fieldErrors.MUST001'), testRequiredPayerPopulated)
      }),
    [UB04_FIELDS.insured58aFirstName]: yup
      .string()
      .nullable()
      .when([UB04_FIELDS.payer50A, UB04_FIELDS.payer50B, UB04_FIELDS.payer50C], {
        is: (payerA: string, payerB: string, payerC: string) => {
          if (getDestinationPayer(payerA, payerB, payerC) === 'A') return true;
          return false;
        },
        then: (schema) =>
          schema
            .transform((v, o) => (o === '' ? null : v))
            .required(t('common:fieldErrors.MUST001'))
            .matches(alphaSpecChar, t('common:fieldErrors.VALID001', { validation: alphaSpecCharVal }))
            .min(1, t('common:fieldErrors.LEN001', { length: `(1-35)` }))
            .max(35, t('common:fieldErrors.LEN001', { length: `(1-35)` }))
            .required(t('common:fieldErrors.MUST001'))
            .test('custom-insured58aFirstNamePayer', t('common:fieldErrors.NAME001'), testPayer)
            .test('custom-insured58aFirstNameRequired', t('common:fieldErrors.MUST001'), testRequiredPayerPopulated)
      }),
    [UB04_FIELDS.insured58aMiddleInitial]: yup
      .string()
      .nullable()
      .when([UB04_FIELDS.payer50A, UB04_FIELDS.payer50B, UB04_FIELDS.payer50C], {
        is: (payerA: string, payerB: string, payerC: string) => {
          if (getDestinationPayer(payerA, payerB, payerC) === 'A') return true;
          return false;
        },
        then: (schema) =>
          schema
            .transform((v, o) => (o === '' ? null : v))
            .matches(alpha, t('common:fieldErrors.VALID001', { validation: alphaVal }))
            .min(1, t('common:fieldErrors.LEN001', { length: `(1-10)` }))
            .max(10, t('common:fieldErrors.LEN001', { length: `(1-10)` }))
      }),
    [UB04_FIELDS.insured58aGen]: yup.string().nullable(),
    [UB04_FIELDS.insured59aRelationship]: genericValidation(t, 1, 2, alphaNum, alphaNumVal),
    [UB04_FIELDS.insured60aUniqueId]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .when([UB04_FIELDS.payer50A, UB04_FIELDS.payer50B, UB04_FIELDS.payer50C], {
        is: (payerA: string, payerB: string, payerC: string) => {
          if (getDestinationPayer(payerA, payerB, payerC) === 'A') return true;
          return false;
        },
        then: (schema) =>
          schema
            .transform((v, o) => (o === '' ? null : v))
            .required(t('common:fieldErrors.MUST001'))
            .test('custom-insured60aUniqueIdRequired', t('common:fieldErrors.MUST001'), testRequiredPayerPopulated)
            .test(
              'custom-insured60aUniqueIdNumeric',
              t('common:fieldErrors.VALID001', { validation: numericVal }),
              testRequiredRegexNumericByLob
            )
            .test(
              'custom-insured60aUniqueIdAlphanumeric',
              t('common:fieldErrors.AlphaInsured'),
              testRequiredRegexAlphanumByLob
            )
            .test('custom-insured60aUniqueIw', t('common:fieldErrors.AlphaIW'), testUniqueIdIfLobIw)

            .min(1, t('common:fieldErrors.LEN001', { length: `(${1}-${20})` }))
            .max(20, t('common:fieldErrors.LEN001', { length: `(${1}-${20})` }))
      }),

    // .min(1, t('common:fieldErrors.LEN001', { length: `(${1}-${20})` }))
    // .max(20, t('common:fieldErrors.LEN001', { length: `(${1}-${20})` })),

    [UB04_FIELDS.insured61aGroupName]: genericValidation(t, 1, 40, alphaNumSpecChar, alphaNumSpecCharVal),
    [UB04_FIELDS.insured62aGroupNumber]: genericValidation(t, 1, 30, alphaNum, alphaNumVal),

    [UB04_FIELDS.insured58bLastName]: yup
      .string()
      .nullable()
      .when([UB04_FIELDS.payer50A, UB04_FIELDS.payer50B, UB04_FIELDS.payer50C], {
        is: (payerA: string, payerB: string, payerC: string) => {
          if (getDestinationPayer(payerA, payerB, payerC) === 'B') return true;
          return false;
        },
        then: (schema) =>
          schema
            .transform((v, o) => (o === '' ? null : v))
            .matches(alphaSpecChar, t('common:fieldErrors.VALID001', { validation: alphaSpecCharVal }))
            .min(1, t('common:fieldErrors.LEN001', { length: `(1-60)` }))
            .max(60, t('common:fieldErrors.LEN001', { length: `(1-60)` }))
            .test('custom-insured58bLastNamePayer', t('common:fieldErrors.NAME001'), testPayer)
            .test('custom-insured58bLastName', t('common:fieldErrors.NAME001'), testInsured58bFirstName)
            .test('custom-insured58bLastNameRequired', t('common:fieldErrors.MUST001'), testRequiredPayerPopulated)
      }),
    [UB04_FIELDS.insured58bFirstName]: yup
      .string()
      .nullable()
      .when([UB04_FIELDS.payer50A, UB04_FIELDS.payer50B, UB04_FIELDS.payer50C], {
        is: (payerA: string, payerB: string, payerC: string) => {
          if (getDestinationPayer(payerA, payerB, payerC) === 'B') return true;
          return false;
        },
        then: (schema) =>
          schema
            .transform((v, o) => (o === '' ? null : v))
            .matches(alphaSpecChar, t('common:fieldErrors.VALID001', { validation: alphaSpecCharVal }))
            .min(1, t('common:fieldErrors.LEN001', { length: `(1-35)` }))
            .max(35, t('common:fieldErrors.LEN001', { length: `(1-35)` }))
            .test('custom-insured58bFirstName', t('common:fieldErrors.NAME001'), testInsured58bLastName)
            .test('custom-insured58bFirstNamePayer', t('common:fieldErrors.NAME001'), testPayer)
            .test('custom-insured58bFirstNameRequired', t('common:fieldErrors.MUST001'), testRequiredPayerPopulated)
      }),
    [UB04_FIELDS.insured58bMiddleInitial]: yup
      .string()
      .nullable()
      .when([UB04_FIELDS.payer50A, UB04_FIELDS.payer50B, UB04_FIELDS.payer50C], {
        is: (payerA: string, payerB: string, payerC: string) => {
          if (getDestinationPayer(payerA, payerB, payerC) === 'B') return true;
          return false;
        },
        then: (schema) =>
          schema
            .transform((v, o) => (o === '' ? null : v))
            .matches(alpha, t('common:fieldErrors.VALID001', { validation: alphaVal }))
            .min(1, t('common:fieldErrors.LEN001', { length: `(1-10)` }))
            .max(10, t('common:fieldErrors.LEN001', { length: `(1-10)` }))
      }),
    [UB04_FIELDS.insured58bGen]: yup.string().nullable(),
    [UB04_FIELDS.insured59bRelationship]: genericValidation(t, 1, 2, alphaNum, alphaNumVal),
    [UB04_FIELDS.insured60bUniqueId]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .when([UB04_FIELDS.payer50A, UB04_FIELDS.payer50B, UB04_FIELDS.payer50C], {
        is: (payerA: string, payerB: string, payerC: string) => {
          if (getDestinationPayer(payerA, payerB, payerC) === 'B') return true;
          return false;
        },
        then: (schema) =>
          schema
            .transform((v, o) => (o === '' ? null : v))
            .required(t('common:fieldErrors.MUST001'))
            // .test('custom-insured60aUniqueIdRequired', t('common:fieldErrors.MUST001'), testRequiredPayerPopulated)
            .test(
              'custom-insured60aUniqueIdNumeric',
              t('common:fieldErrors.VALID001', { validation: numericVal }),
              testRequiredRegexNumericByLob
            )
            .test(
              'custom-insured60aUniqueIdAlphanumeric',
              t('common:fieldErrors.AlphaInsured'),
              testRequiredRegexAlphanumByLob
            )
            .test('custom-insured60aUniqueIw', t('common:fieldErrors.AlphaIW'), testUniqueIdIfLobIw)
            .min(1, t('common:fieldErrors.LEN001', { length: `(${1}-${20})` }))
            .max(20, t('common:fieldErrors.LEN001', { length: `(${1}-${20})` }))
      }),

    // .min(1, t('common:fieldErrors.LEN001', { length: `(${1}-${20})` }))
    // .max(20, t('common:fieldErrors.LEN001', { length: `(${1}-${20})` })),
    [UB04_FIELDS.insured61bGroupName]: genericValidation(t, 1, 40, alphaNumSpecChar, alphaNumSpecCharVal),
    [UB04_FIELDS.insured62bGroupNumber]: genericValidation(t, 1, 30, alphaNum, alphaNumVal),

    [UB04_FIELDS.insured58cLastName]: yup
      .string()
      .nullable()
      .when([UB04_FIELDS.payer50A, UB04_FIELDS.payer50B, UB04_FIELDS.payer50C], {
        is: (payerA: string, payerB: string, payerC: string) => {
          if (getDestinationPayer(payerA, payerB, payerC) === 'C') return true;
          return false;
        },
        then: (schema) =>
          schema
            .transform((v, o) => (o === '' ? null : v))
            .matches(alphaSpecChar, t('common:fieldErrors.VALID001', { validation: alphaSpecCharVal }))
            .min(1, t('common:fieldErrors.LEN001', { length: `(1-60)` }))
            .max(60, t('common:fieldErrors.LEN001', { length: `(1-60)` }))
            .test('custom-insured58clastNamePayer', t('common:fieldErrors.NAME001'), testPayer)
            .test('custom-insured58cLastName', t('common:fieldErrors.NAME001'), testInsured58cFirstName)
            .test('custom-insured58cLastNameRequired', t('common:fieldErrors.MUST001'), testRequiredPayerPopulated)
      }),
    [UB04_FIELDS.insured58cFirstName]: yup
      .string()
      .nullable()
      .when([UB04_FIELDS.payer50A, UB04_FIELDS.payer50B, UB04_FIELDS.payer50C], {
        is: (payerA: string, payerB: string, payerC: string) => {
          if (getDestinationPayer(payerA, payerB, payerC) === 'C') return true;
          return false;
        },
        then: (schema) =>
          schema
            .transform((v, o) => (o === '' ? null : v))
            .matches(alphaSpecChar, t('common:fieldErrors.VALID001', { validation: alphaSpecCharVal }))
            .min(1, t('common:fieldErrors.LEN001', { length: `(1-35)` }))
            .max(35, t('common:fieldErrors.LEN001', { length: `(1-35)` }))
            .test('custom-insured58CFirstNamePayer', t('common:fieldErrors.NAME001'), testPayer)
            .test('custom-insured58cFirstName', t('common:fieldErrors.NAME001'), testInsured58cLastName)
            .test('custom-insured58cFirstNameRequired', t('common:fieldErrors.MUST001'), testRequiredPayerPopulated)
      }),
    [UB04_FIELDS.insured58cMiddleInitial]: yup
      .string()
      .nullable()
      .when([UB04_FIELDS.payer50A, UB04_FIELDS.payer50B, UB04_FIELDS.payer50C], {
        is: (payerA: string, payerB: string, payerC: string) => {
          if (getDestinationPayer(payerA, payerB, payerC) === 'C') return true;
          return false;
        },
        then: (schema) =>
          schema
            .transform((v, o) => (o === '' ? null : v))
            .matches(alpha, t('common:fieldErrors.VALID001', { validation: alphaVal }))
            .min(1, t('common:fieldErrors.LEN001', { length: `(1-10)` }))
            .max(10, t('common:fieldErrors.LEN001', { length: `(1-10)` }))
      }),
    [UB04_FIELDS.insured58cGen]: yup.string().nullable(),
    [UB04_FIELDS.insured59cRelationship]: genericValidation(t, 1, 2, alphaNum, alphaNumVal),
    [UB04_FIELDS.insured60cUniqueId]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .when([UB04_FIELDS.payer50A, UB04_FIELDS.payer50B, UB04_FIELDS.payer50C], {
        is: (payerA: string, payerB: string, payerC: string) => {
          if (getDestinationPayer(payerA, payerB, payerC) === 'C') return true;
          return false;
        },
        then: (schema) =>
          schema
            .transform((v, o) => (o === '' ? null : v))
            .required(t('common:fieldErrors.MUST001'))
            // .test('custom-insured60aUniqueIdRequired', t('common:fieldErrors.MUST001'), testRequiredPayerPopulated)
            .test(
              'custom-insured60aUniqueIdNumeric',
              t('common:fieldErrors.VALID001', { validation: numericVal }),
              testRequiredRegexNumericByLob
            )
            .test(
              'custom-insured60aUniqueIdAlphanumeric',
              t('common:fieldErrors.AlphaInsured'),
              testRequiredRegexAlphanumByLob
            )
            .test('custom-insured60aUniqueIw', t('common:fieldErrors.AlphaIW'), testUniqueIdIfLobIw)

            .min(1, t('common:fieldErrors.LEN001', { length: `(${1}-${20})` }))
            .max(20, t('common:fieldErrors.LEN001', { length: `(${1}-${20})` }))
      }),
    // .min(1, t('common:fieldErrors.LEN001', { length: `(${1}-${20})` }))
    // .max(20, t('common:fieldErrors.LEN001', { length: `(${1}-${20})` })),

    [UB04_FIELDS.insured61cGroupName]: genericValidation(t, 1, 40, alphaNumSpecChar, alphaNumSpecCharVal),
    [UB04_FIELDS.insured62cGroupNumber]: genericValidation(t, 1, 30, alphaNum, alphaNumVal),

    [UB04_FIELDS.payer50A]: payer50(t).test(
      'custom-payer50AUnique',
      t('common:fieldErrors.CareSourceNotListed'),
      (value, context) => testOtherPayer(value, context, UB04_FIELDS.payer50B, UB04_FIELDS.payer50C)
    ),
    // .test('custom-payer50A', t('common:fieldErrors.CareSourceNotListed'), testPayerRegexCheck),
    [UB04_FIELDS.healthPlan51A]: payer50(t),
    // .test(
    //   'custom-healthPlan51ARequired',
    //   t('common:fieldErrors.MUST001'),
    //   testRequiredPayerPopulated
    // )
    [UB04_FIELDS.releaseInfo52A]: yup.string().nullable(),
    [UB04_FIELDS.assignmentOfBenefits53A]: yup.string().nullable(),
    [UB04_FIELDS.priorPayments54A]: genericValidation(t, 1, 20, numericDecimal, numericDecimalVal),
    [UB04_FIELDS.estimatedAmount55A]: genericValidation(t, 1, 20, numericDecimal, numericDecimalVal),
    [UB04_FIELDS.otherBillingId57A]: otherId57(t),

    [UB04_FIELDS.payer50B]: payer50(t).test(
      'custom-payer50BUnique',
      t('common:fieldErrors.CareSourceNotListed'),
      (value, context) => testOtherPayer(value, context, UB04_FIELDS.payer50A, UB04_FIELDS.payer50C)
    ),
    // .test('custom-payer50B', t('common:fieldErrors.CareSourceNotListed'), testPayerRegexCheck),
    [UB04_FIELDS.healthPlan51B]: payer50(t),
    // .test(
    //   'custom-healthPlan51BRequired',
    //   t('common:fieldErrors.MUST001'),
    //   testRequiredPayerPopulated
    // )
    [UB04_FIELDS.releaseInfo52B]: yup.string().nullable(),
    [UB04_FIELDS.assignmentOfBenefits53B]: yup.string().nullable(),
    [UB04_FIELDS.priorPayments54B]: genericValidation(t, 1, 20, numericDecimal, numericDecimalVal),
    [UB04_FIELDS.estimatedAmount55B]: genericValidation(t, 1, 20, numericDecimal, numericDecimalVal),
    [UB04_FIELDS.otherBillingId57B]: otherId57(t),
    [UB04_FIELDS.ub_total_charges_sum]: yup.string().nullable(),

    [UB04_FIELDS.payer50C]: payer50(t).test(
      'custom-payer50CUnique',
      t('common:fieldErrors.CareSourceNotListed'),
      (value, context) => testOtherPayer(value, context, UB04_FIELDS.payer50A, UB04_FIELDS.payer50B)
    ),
    // .test('custom-payer50C', t('common:fieldErrors.CareSourceNotListed'), testPayerRegexCheck),
    [UB04_FIELDS.healthPlan51C]: payer50(t),
    // .test(
    //   'custom-healthPlan51CRequired',
    //   t('common:fieldErrors.MUST001'),
    //   testRequiredPayerPopulated
    // )
    [UB04_FIELDS.releaseInfo52C]: yup.string().nullable(),
    [UB04_FIELDS.assignmentOfBenefits53C]: yup.string().nullable(),
    [UB04_FIELDS.priorPayments54C]: genericValidation(t, 1, 20, numericDecimal, numericDecimalVal),
    [UB04_FIELDS.estimatedAmount55C]: genericValidation(t, 1, 20, numericDecimal, numericDecimalVal),
    [UB04_FIELDS.otherBillingId57C]: otherId57(t),

    [UB04_FIELDS.dischargeStatus]: genericValidation(t, 1, 2, numeric, numericVal).required(
      t('common:fieldErrors.MUST001')
    ),
    [UB04_FIELDS.conditionCode18]: conditionCodeValidation(t),
    // .test(
    //   'condition-lookup18',
    //   'Please review the entered value, it is not found in the standard list of values',
    //   testConditionCodeLookup
    // ),
    [UB04_FIELDS.conditionCode19]: conditionCodeValidation(t),
    // .test(
    //   'condition-lookup19',
    //   'Please review the entered value, it is not found in the standard list of values',
    //   testConditionCodeLookup
    // ),
    [UB04_FIELDS.conditionCode20]: conditionCodeValidation(t),
    // .test(
    //   'condition-lookup20',
    //   'Please review the entered value, it is not found in the standard list of values',
    //   testConditionCodeLookup
    // ),
    [UB04_FIELDS.conditionCode21]: conditionCodeValidation(t),
    // .test(
    //   'condition-lookup21',
    //   'Please review the entered value, it is not found in the standard list of values',
    //   testConditionCodeLookup
    // ),
    [UB04_FIELDS.conditionCode22]: conditionCodeValidation(t),
    // .test(
    //   'condition-lookup22',
    //   'Please review the entered value, it is not found in the standard list of values',
    //   testConditionCodeLookup
    // ),
    [UB04_FIELDS.conditionCode23]: conditionCodeValidation(t),
    // .test(
    //   'condition-lookup23',
    //   'Please review the entered value, it is not found in the standard list of values',
    //   testConditionCodeLookup
    // ),
    [UB04_FIELDS.conditionCode24]: conditionCodeValidation(t),
    // .test(
    //   'condition-lookup24',
    //   'Please review the entered value, it is not found in the standard list of values',
    //   testConditionCodeLookup
    // ),
    [UB04_FIELDS.conditionCode25]: conditionCodeValidation(t),
    // .test(
    //   'condition-lookup25',
    //   'Please review the entered value, it is not found in the standard list of values',
    //   testConditionCodeLookup
    // ),
    [UB04_FIELDS.conditionCode26]: conditionCodeValidation(t),
    // .test(
    //   'condition-lookup26',
    //   'Please review the entered value, it is not found in the standard list of values',
    //   testConditionCodeLookup
    // ),
    [UB04_FIELDS.conditionCode27]: conditionCodeValidation(t),
    // .test(
    //   'condition-lookup27',
    //   'Please review the entered value, it is not found in the standard list of values',
    //   testConditionCodeLookup
    // ),
    [UB04_FIELDS.conditionCode28]: conditionCodeValidation(t),
    // .test(
    //   'condition-lookup18',
    //   'Please review the entered value, it is not found in the standard list of values',
    //   testConditionCodeLookup
    // ),
    [UB04_FIELDS.accidentState]: genericValidationFixLeng(t, 2, alphaNum, alphaNumVal),

    [UB04_FIELDS.principalProcedureCode]: genericValidation(t, 1, 8, alphaNum, alphaNumVal),
    [UB04_FIELDS.procedureCode74a]: genericValidation(t, 1, 8, alphaNum, alphaNumVal),
    [UB04_FIELDS.procedureCode74b]: genericValidation(t, 1, 8, alphaNum, alphaNumVal),
    [UB04_FIELDS.procedureCode74c]: genericValidation(t, 1, 8, alphaNum, alphaNumVal),
    [UB04_FIELDS.procedureCode74d]: genericValidation(t, 1, 8, alphaNum, alphaNumVal),
    [UB04_FIELDS.procedureCode74e]: genericValidation(t, 1, 8, alphaNum, alphaNumVal),

    [UB04_FIELDS.principalProcedureDate]: occurrenceDateValidation(t)
      .test('custom-principalProcedureDatefuture', t('common:fieldErrors.DATE001'), testFutureDate)
      .test('custom-principalProcedureDateEarlierDOB', t('common:fieldErrors.DOS003'), (value, context) =>
        testEarlierPatientDOBById(value, context, UB04_FIELDS.principalProcedureDate)
      )
      .test('custom-principalProcedureDate', t('common:fieldErrors.VALID004'), testProcedureCode)
      .test('custom-validDate', dateInvalidMsg, testIsDateValid),
    [UB04_FIELDS.procedureDate74a]: occurrenceDateValidation(t)
      .test('custom-procedureDate74afuture', t('common:fieldErrors.DATE001'), testFutureDate)
      .test('custom-procedureDate74AEarlierDOB', t('common:fieldErrors.DOS003'), (value, context) =>
        testEarlierPatientDOBById(value, context, UB04_FIELDS.procedureDate74a)
      )
      .test('custom-procedureDate74a', t('common:fieldErrors.VALID004'), testProcedureCode)
      .test('custom-validDate', dateInvalidMsg, testIsDateValid),
    [UB04_FIELDS.procedureDate74b]: occurrenceDateValidation(t)
      .test('custom-procedureDate74bfuture', t('common:fieldErrors.DATE001'), testFutureDate)

      .test('custom-procedureDate74BEarlierDOB', t('common:fieldErrors.DOS003'), (value, context) =>
        testEarlierPatientDOBById(value, context, UB04_FIELDS.procedureDate74b)
      )
      .test('custom-procedureDate74b', t('common:fieldErrors.VALID004'), testProcedureCode)
      .test('custom-validDate', dateInvalidMsg, testIsDateValid),
    [UB04_FIELDS.procedureDate74c]: occurrenceDateValidation(t)
      .test('custom-procedureDate74cfuture', t('common:fieldErrors.DATE001'), testFutureDate)
      .test('custom-procedureDate74CEarlierDOB', t('common:fieldErrors.DOS003'), (value, context) =>
        testEarlierPatientDOBById(value, context, UB04_FIELDS.procedureDate74c)
      )
      .test('custom-procedureDate74c', t('common:fieldErrors.VALID004'), testProcedureCode)
      .test('custom-validDate', dateInvalidMsg, testIsDateValid),
    [UB04_FIELDS.procedureDate74d]: occurrenceDateValidation(t)
      .test('custom-procedureDate74dfuture', t('common:fieldErrors.DATE001'), testFutureDate)
      .test('custom-procedureDate74DEarlierDOB', t('common:fieldErrors.DOS003'), (value, context) =>
        testEarlierPatientDOBById(value, context, UB04_FIELDS.procedureDate74d)
      )
      .test('custom-procedureDate74d', t('common:fieldErrors.VALID004'), testProcedureCode)
      .test('custom-validDate', dateInvalidMsg, testIsDateValid),
    [UB04_FIELDS.procedureDate74e]: occurrenceDateValidation(t)
      .test('custom-procedureDate74efuture', t('common:fieldErrors.DATE001'), testFutureDate)
      .test('custom-procedureDate74EEarlierDOB', t('common:fieldErrors.DOS003'), (value, context) =>
        testEarlierPatientDOBById(value, context, UB04_FIELDS.procedureDate74e)
      )
      .test('custom-procedureDate74e', t('common:fieldErrors.VALID004'), testProcedureCode)
      .test('custom-validDate', dateInvalidMsg, testIsDateValid),

    [UB04_FIELDS.occurrenceCode31A]: genericValidation(t, 1, 2, alphaNum, alphaNumVal),
    [UB04_FIELDS.occurrenceCode31B]: genericValidation(t, 1, 2, alphaNum, alphaNumVal),
    [UB04_FIELDS.occurrenceCode32A]: genericValidation(t, 1, 2, alphaNum, alphaNumVal),
    [UB04_FIELDS.occurrenceCode32B]: genericValidation(t, 1, 2, alphaNum, alphaNumVal),
    [UB04_FIELDS.occurrenceCode33A]: genericValidation(t, 1, 2, alphaNum, alphaNumVal),
    [UB04_FIELDS.occurrenceCode33B]: genericValidation(t, 1, 2, alphaNum, alphaNumVal),
    [UB04_FIELDS.occurrenceCode34A]: genericValidation(t, 1, 2, alphaNum, alphaNumVal),
    // .test(
    //   'occurrence-lookub34A',
    //   'Please review the entered value, it is not found in the standard list of values',
    //   occuranceCodeLookup
    // ),
    [UB04_FIELDS.occurrenceCode34B]: genericValidation(t, 1, 2, alphaNum, alphaNumVal),
    // .test(
    //   'occurrence-lookub34B',
    //   'Please review the entered value, it is not found in the standard list of values',
    //   occuranceCodeLookup
    // ),

    [UB04_FIELDS.occurrenceDate31A]: occurrenceDateValidation(t)
      .test('custom-occurrenceDate31Afuture', t('common:fieldErrors.DATE001'), testFutureDate)
      .test('custom-validDate', dateInvalidMsg, testIsDateValid),
    [UB04_FIELDS.occurrenceDate31B]: occurrenceDateValidation(t)
      .test('custom-occurrenceDate31Bfuture', t('common:fieldErrors.DATE001'), testFutureDate)
      .test('custom-validDate', dateInvalidMsg, testIsDateValid),
    [UB04_FIELDS.occurrenceDate32A]: occurrenceDateValidation(t)
      .test('custom-occurrenceDate32Afuture', t('common:fieldErrors.DATE001'), testFutureDate)
      .test('custom-validDate', dateInvalidMsg, testIsDateValid),
    [UB04_FIELDS.occurrenceDate32B]: occurrenceDateValidation(t)
      .test('custom-occurrenceDate32Bfuture', t('common:fieldErrors.DATE001'), testFutureDate)
      .test('custom-validDate', dateInvalidMsg, testIsDateValid),
    [UB04_FIELDS.occurrenceDate33A]: occurrenceDateValidation(t)
      .test('custom-occurrenceDate33Afuture', t('common:fieldErrors.DATE001'), testFutureDate)
      .test('custom-validDate', dateInvalidMsg, testIsDateValid),
    [UB04_FIELDS.occurrenceDate33B]: occurrenceDateValidation(t)
      .test('custom-occurrenceDate33Bfuture', t('common:fieldErrors.DATE001'), testFutureDate)
      .test('custom-validDate', dateInvalidMsg, testIsDateValid),
    [UB04_FIELDS.occurrenceDate34A]: occurrenceDateValidation(t)
      .test('custom-occurrenceDate34Afuture', t('common:fieldErrors.DATE001'), testFutureDate)
      .test('custom-validDate', dateInvalidMsg, testIsDateValid),
    [UB04_FIELDS.occurrenceDate34B]: occurrenceDateValidation(t)
      .test('custom-occurrenceDate34Bfuture', t('common:fieldErrors.DATE001'), testFutureDate)
      .test('custom-validDate', dateInvalidMsg, testIsDateValid),

    [UB04_FIELDS.occurrenceSpan35ACode]: genericValidation(t, 1, 2, alphaNum, alphaNumVal)
      .test('custom-occurrenceSpan35ACode', '', (value, context) => testOccurrenceSpanCode(value, context, yup))
      .test('custom-occurrenceSpan35AFromRequired', t('common:fieldErrors.VALID003'), (value, context) =>
        testIsOccurenceSpanEmpty(value, context, UB04_FIELDS.occurrenceSpan35AFrom, UB04_FIELDS.occurrenceSpan35ATo)
      ),
    [UB04_FIELDS.occurrenceSpan35BCode]: genericValidation(t, 1, 2, alphaNum, alphaNumVal)
      .test('custom-occurrenceSpan35BCode', '', (value, context) => testOccurrenceSpanCode(value, context, yup))
      .test('custom-occurrenceSpan35AFromRequired', t('common:fieldErrors.VALID003'), (value, context) =>
        testIsOccurenceSpanEmpty(value, context, UB04_FIELDS.occurrenceSpan35BFrom, UB04_FIELDS.occurrenceSpan35BTo)
      ),
    [UB04_FIELDS.occurrenceSpan36ACode]: genericValidation(t, 1, 2, alphaNum, alphaNumVal)
      .test('custom-occurrenceSpan36ACode', '', (value, context) => testOccurrenceSpanCode(value, context, yup))
      .test('custom-occurrenceSpan35AFromRequired', t('common:fieldErrors.VALID003'), (value, context) =>
        testIsOccurenceSpanEmpty(value, context, UB04_FIELDS.occurrenceSpan36AFrom, UB04_FIELDS.occurrenceSpan36ATo)
      ),
    [UB04_FIELDS.occurrenceSpan36BCode]: genericValidation(t, 1, 2, alphaNum, alphaNumVal)
      .test('custom-occurrenceSpan36BCode', '', (value, context) => testOccurrenceSpanCode(value, context, yup))
      .test('custom-occurrenceSpan35AFromRequired', t('common:fieldErrors.VALID003'), (value, context) =>
        testIsOccurenceSpanEmpty(value, context, UB04_FIELDS.occurrenceSpan36BFrom, UB04_FIELDS.occurrenceSpan36BTo)
      ),

    [UB04_FIELDS.occurrenceSpan35AFrom]: occurrenceDateValidation(t)
      .test('custom-occurrenceSpan35AFromfuture', t('common:fieldErrors.DATE001'), testFutureDate)
      .test('custom-ocurrenceSpan35AFromEarlierDOB', t('common:fieldErrors.DOS003'), (value, context) =>
        testEarlierPatientDOBById(value, context, UB04_FIELDS.occurrenceSpan35AFrom)
      )
      .test('customOcurrenceSpan35AFromRequired', t('common:fieldErrors.VALID008'), (value, context) =>
        testRequiredOccurrenceDate(value, context)
      )
      .test('custom-OccurenceCode35IsCodeEmpty', t('common:fieldErrors.VALID003'), (value, context) =>
        testIsOccurenceCodeEmpty(value, context, UB04_FIELDS.occurrenceSpan35ACode)
      )
      .test('custom-validDate', dateInvalidMsg, testIsDateValid),
    [UB04_FIELDS.occurrenceSpan35ATo]: occurrenceDateValidation(t)
      .test('custom-occurrenceSpan35ATofuture', t('common:fieldErrors.DATE001'), testFutureDate)
      .test('custom-ocurrenceSpan35AToEarlierFrom', t('common:fieldErrors.DOS002'), testLaterThanOccurence35SpanDate)
      .test('custom-ocurrenceSpan35AToEqualFrom', t('common:fieldErrors.DOS006'), (value, context) =>
        testOcurrenceSpanEqualFrom(value, context, values[UB04_FIELDS.occurrenceSpan35AFrom])
      )
      .test('custom-ocurrenceSpan35AToEarlierDOB', t('common:fieldErrors.DOS003'), (value, context) =>
        testEarlierPatientDOBById(value, context, UB04_FIELDS.occurrenceSpan35ATo)
      )
      .test('customOcurrenceSpan35AToRequired', t('common:fieldErrors.VALID008'), (value, context) =>
        testRequiredOccurrenceDate(value, context)
      )
      .test('custom-OccurenceCode35IsCodeEmpty', t('common:fieldErrors.VALID003'), (value, context) =>
        testIsOccurenceCodeEmpty(value, context, UB04_FIELDS.occurrenceSpan35ACode)
      )
      .test('custom-validDate', dateInvalidMsg, testIsDateValid),
    [UB04_FIELDS.occurrenceSpan35BFrom]: occurrenceDateValidation(t)
      .test('custom-occurrenceSpan35BFromfuture', t('common:fieldErrors.DATE001'), testFutureDate)
      .test('custom-ocurrenceSpan35BFromEarlierDOB', t('common:fieldErrors.DOS003'), (value, context) =>
        testEarlierPatientDOBById(value, context, UB04_FIELDS.occurrenceSpan35BFrom)
      )
      .test('customOcurrenceSpan35BFromRequired', t('common:fieldErrors.VALID008'), (value, context) =>
        testRequiredOccurrenceDate(value, context)
      )
      .test('custom-OccurenceCode35IsCodeEmpty', t('common:fieldErrors.VALID003'), (value, context) =>
        testIsOccurenceCodeEmpty(value, context, UB04_FIELDS.occurrenceSpan35BCode)
      )
      .test('custom-validDate', dateInvalidMsg, testIsDateValid),
    [UB04_FIELDS.occurrenceSpan35BTo]: occurrenceDateValidation(t)
      .test('custom-occurrenceSpan35BTofuture', t('common:fieldErrors.DATE001'), testFutureDate)
      .test('custom-ocurrenceSpan35BToEarlierFrom', t('common:fieldErrors.DOS002'), testLaterThanOccurence35BSpanDate)
      .test('custom-ocurrenceSpan35BToEqualFrom', t('common:fieldErrors.DOS006'), (value, context) =>
        testOcurrenceSpanEqualFrom(value, context, values[UB04_FIELDS.occurrenceSpan35BFrom])
      )
      .test('custom-ocurrenceSpan35BToEarlierDOB', t('common:fieldErrors.DOS003'), (value, context) =>
        testEarlierPatientDOBById(value, context, UB04_FIELDS.occurrenceSpan35BTo)
      )
      .test('customOcurrenceSpan35BToRequired', t('common:fieldErrors.VALID008'), (value, context) =>
        testRequiredOccurrenceDate(value, context)
      )
      .test('custom-OccurenceCode35IsCodeEmpty', t('common:fieldErrors.VALID003'), (value, context) =>
        testIsOccurenceCodeEmpty(value, context, UB04_FIELDS.occurrenceSpan35BCode)
      )
      .test('custom-validDate', dateInvalidMsg, testIsDateValid),
    [UB04_FIELDS.occurrenceSpan36AFrom]: occurrenceDateValidation(t)
      .test('custom-occurrenceSpan36AFromfuture', t('common:fieldErrors.DATE001'), testFutureDate)
      .test('custom-ocurrenceSpan36AFromEarlierDOB', t('common:fieldErrors.DOS003'), (value, context) =>
        testEarlierPatientDOBById(value, context, UB04_FIELDS.occurrenceSpan36AFrom)
      )
      .test('customOcurrenceSpan36AFromRequired', t('common:fieldErrors.VALID008'), (value, context) =>
        testRequiredOccurrenceDate(value, context)
      )
      .test('custom-OccurenceCode35IsCodeEmpty', t('common:fieldErrors.VALID003'), (value, context) =>
        testIsOccurenceCodeEmpty(value, context, UB04_FIELDS.occurrenceSpan36ACode)
      )
      .test('custom-validDate', dateInvalidMsg, testIsDateValid),
    [UB04_FIELDS.occurrenceSpan36ATo]: occurrenceDateValidation(t)
      .test('custom-occurrenceSpan36ATofuture', t('common:fieldErrors.DATE001'), testFutureDate)
      .test('custom-ocurrenceSpan36AToEarlierFrom', t('common:fieldErrors.DOS002'), testLaterThanOccurence36ASpanDate)
      .test('custom-ocurrenceSpan36AToEqualFrom', t('common:fieldErrors.DOS006'), (value, context) =>
        testOcurrenceSpanEqualFrom(value, context, values[UB04_FIELDS.occurrenceSpan36AFrom])
      )
      .test('custom-ocurrenceSpan36AToEarlierDOB', t('common:fieldErrors.DOS003'), (value, context) =>
        testEarlierPatientDOBById(value, context, UB04_FIELDS.occurrenceSpan36ATo)
      )
      .test('customOcurrenceSpan36AToRequired', t('common:fieldErrors.VALID008'), (value, context) =>
        testRequiredOccurrenceDate(value, context)
      )
      .test('custom-OccurenceCode35IsCodeEmpty', t('common:fieldErrors.VALID003'), (value, context) =>
        testIsOccurenceCodeEmpty(value, context, UB04_FIELDS.occurrenceSpan36ACode)
      )
      .test('custom-validDate', dateInvalidMsg, testIsDateValid),
    [UB04_FIELDS.occurrenceSpan36BFrom]: occurrenceDateValidation(t)
      .test('custom-occurrenceSpan36BFromfuture', t('common:fieldErrors.DATE001'), testFutureDate)
      .test('custom-ocurrenceSpan36BFromEarlierDOB', t('common:fieldErrors.DOS003'), (value, context) =>
        testEarlierPatientDOBById(value, context, UB04_FIELDS.occurrenceSpan36BFrom)
      )
      .test('customOcurrenceSpan36BFromRequired', t('common:fieldErrors.VALID008'), (value, context) =>
        testRequiredOccurrenceDate(value, context)
      )
      .test('custom-OccurenceCode35IsCodeEmpty', t('common:fieldErrors.VALID003'), (value, context) =>
        testIsOccurenceCodeEmpty(value, context, UB04_FIELDS.occurrenceSpan36BCode)
      )
      .test('custom-validDate', dateInvalidMsg, testIsDateValid),
    [UB04_FIELDS.occurrenceSpan36BTo]: occurrenceDateValidation(t)
      .test('custom-ocurrenceSpan36BToFutureDate', t('common:fieldErrors.DOS001'), testFutureDate)
      .test('custom-ocurrenceSpan36BToEarlierFrom', t('common:fieldErrors.DOS002'), testLaterThanOccurence36BSpanDate)
      .test('custom-ocurrenceSpan36BToEqualFrom', t('common:fieldErrors.DOS006'), (value, context) =>
        testOcurrenceSpanEqualFrom(value, context, values[UB04_FIELDS.occurrenceSpan36BFrom])
      )
      .test('custom-ocurrenceSpan36BToEarlierDOB', t('common:fieldErrors.DOS003'), (value, context) =>
        testEarlierPatientDOBById(value, context, UB04_FIELDS.occurrenceSpan36BTo)
      )
      .test('customOcurrenceSpan36BToRequired', t('common:fieldErrors.VALID008'), (value, context) =>
        testRequiredOccurrenceDate(value, context)
      )
      .test('custom-OccurenceCode35IsCodeEmpty', t('common:fieldErrors.VALID003'), (value, context) =>
        testIsOccurenceCodeEmpty(value, context, UB04_FIELDS.occurrenceSpan36BCode)
      )
      .test('custom-validDate', dateInvalidMsg, testIsDateValid),

    [UB04_FIELDS.ub73_loc]: genericValidation(t, 1, 10, alphaNum, alphaNumVal),

    [UB04_FIELDS.responsiblePartyLastName]: genericValidation(t, 1, 60, alphaSpecChar, alphaSpecCharVal),
    [UB04_FIELDS.responsiblePartyFirstName]: genericValidation(t, 1, 35, alphaSpecChar, alphaSpecCharVal),
    [UB04_FIELDS.responsiblePartyMiddleInitial]: genericValidation(t, 1, 10, alpha, alphaVal),
    [UB04_FIELDS.responsiblePartyGen]: yup.string().nullable(),
    [UB04_FIELDS.responsiblePartyAddress]: genericValidation(t, 1, 55, alphaNumSpecChar, alphaNumSpecCharVal),
    [UB04_FIELDS.responsiblePartyCity]: genericValidation(t, 1, 30, alphaSpecChar, alphaSpecCharVal).test(
      'custom-responsiblePartyCity',
      t('common:fieldErrors.ADD001'),
      testResponsiblePartyAddress
    ),
    [UB04_FIELDS.responsiblePartyAddressTwo]: genericValidation(t, 1, 55, alphaNumSpecChar, alphaNumSpecCharVal),
    [UB04_FIELDS.responsiblePartyState]: yup
      .string()
      .nullable()
      .test('custom-responsiblePartyState', t('common:fieldErrors.ADD001'), testResponsiblePartyAddress),
    [UB04_FIELDS.responsiblePartyZip]: commonZipcodeValidation(t).test(
      'custom-reponsiblePartyZip',
      t('common:fieldErrors.ADD001'),
      testResponsiblePartyAddress
    ),

    [UB04_FIELDS.ub37_a]: genericValidation(t, 1, 10, alphaNum, alphaNumVal),
    [UB04_FIELDS.ub37_b]: genericValidation(t, 1, 10, alphaNum, alphaNumVal),

    '39a_value_code': yup.string().required(t('common:fieldErrors.MUST001')),
    [UB04_FIELDS.value39ACode]: valueCodesValidation(t),
    // .test(
    //   'val-lookup39A',
    //   'Please review the entered value, it is not found in the standard list of values',
    //   testValueCodeLookup
    // ),
    [UB04_FIELDS.value39BCode]: valueCodesValidation(t),
    // .test(
    //   'val-lookup39B',
    //   'Please review the entered value, it is not found in the standard list of values',
    //   testValueCodeLookup
    // ),
    [UB04_FIELDS.value39CCode]: valueCodesValidation(t),
    // .test(
    //   'val-lookup39C',
    //   'Please review the entered value, it is not found in the standard list of values',
    //   testValueCodeLookup
    // ),
    [UB04_FIELDS.value39DCode]: valueCodesValidation(t),
    // .test(
    //   'val-lookup39D',
    //   'Please review the entered value, it is not found in the standard list of values',
    //   testValueCodeLookup
    // ),
    [UB04_FIELDS.value40ACode]: valueCodesValidation(t),
    // .test(
    //   'val-lookup40A',
    //   'Please review the entered value, it is not found in the standard list of values',
    //   testValueCodeLookup
    // ),
    [UB04_FIELDS.value40BCode]: valueCodesValidation(t),
    // .test(
    //   'val-lookup40B',
    //   'Please review the entered value, it is not found in the standard list of values',
    //   testValueCodeLookup
    // ),
    [UB04_FIELDS.value40CCode]: valueCodesValidation(t),
    // .test(
    //   'val-lookup40C',
    //   'Please review the entered value, it is not found in the standard list of values',
    //   testValueCodeLookup
    // ),
    [UB04_FIELDS.value40DCode]: valueCodesValidation(t),
    // .test(
    //   'val-lookup40C',
    //   'Please review the entered value, it is not found in the standard list of values',
    //   testValueCodeLookup
    // ),
    [UB04_FIELDS.value41ACode]: valueCodesValidation(t),
    [UB04_FIELDS.value41BCode]: valueCodesValidation(t),
    [UB04_FIELDS.value41CCode]: valueCodesValidation(t),
    [UB04_FIELDS.value41DCode]: valueCodesValidation(t),
    [UB04_FIELDS.value39AAmount]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(numericDecimal, t('common:fieldErrors.VALID001', { validation: numericDecimalVal }))
      .min(2, t('common:fieldErrors.LEN001', { length: '(2-20)' }))
      .max(20, t('common:fieldErrors.LEN001', { length: '(2-20)' }))
      .test('custom-value39AAmount', t('common:fieldErrors.VALID002'), testValueAmountCode),
    [UB04_FIELDS.value39BAmount]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(numericDecimal, t('common:fieldErrors.VALID001', { validation: numericDecimalVal }))
      .min(2, t('common:fieldErrors.LEN001', { length: '(2-20)' }))
      .max(20, t('common:fieldErrors.LEN001', { length: '(2-20)' }))
      .test('custom-value39BAmount', t('common:fieldErrors.VALID002'), testValueAmountCode),
    [UB04_FIELDS.value39CAmount]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(numericDecimal, t('common:fieldErrors.VALID001', { validation: numericDecimalVal }))
      .min(2, t('common:fieldErrors.LEN001', { length: '(2-20)' }))
      .max(20, t('common:fieldErrors.LEN001', { length: '(2-20)' }))
      .test('custom-value39CAmount', t('common:fieldErrors.VALID002'), testValueAmountCode),
    [UB04_FIELDS.value39DAmount]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(numericDecimal, t('common:fieldErrors.VALID001', { validation: numericDecimalVal }))
      .min(2, t('common:fieldErrors.LEN001', { length: '(2-20)' }))
      .max(20, t('common:fieldErrors.LEN001', { length: '(2-20)' }))
      .test('custom-value39DAmount', t('common:fieldErrors.VALID002'), testValueAmountCode),
    [UB04_FIELDS.value40AAmount]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(numericDecimal, t('common:fieldErrors.VALID001', { validation: numericDecimalVal }))
      .min(2, t('common:fieldErrors.LEN001', { length: '(2-20)' }))
      .max(20, t('common:fieldErrors.LEN001', { length: '(2-20)' }))
      .test('custom-value40AAmount', t('common:fieldErrors.VALID002'), testValueAmountCode),
    [UB04_FIELDS.value40BAmount]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(numericDecimal, t('common:fieldErrors.VALID001', { validation: numericDecimalVal }))
      .min(2, t('common:fieldErrors.LEN001', { length: '(2-20)' }))
      .max(20, t('common:fieldErrors.LEN001', { length: '(2-20)' }))
      .test('custom-value40BAmount', t('common:fieldErrors.VALID002'), testValueAmountCode),
    [UB04_FIELDS.value40CAmount]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(numericDecimal, t('common:fieldErrors.VALID001', { validation: numericDecimalVal }))
      .min(2, t('common:fieldErrors.LEN001', { length: '(2-20)' }))
      .max(20, t('common:fieldErrors.LEN001', { length: '(2-20)' }))
      .test('custom-value40CAmount', t('common:fieldErrors.VALID002'), testValueAmountCode),
    [UB04_FIELDS.value40DAmount]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(numericDecimal, t('common:fieldErrors.VALID001', { validation: numericDecimalVal }))
      .min(2, t('common:fieldErrors.LEN001', { length: '(2-20)' }))
      .max(20, t('common:fieldErrors.LEN001', { length: '(2-20)' }))
      .test('custom-value40DAmount', t('common:fieldErrors.VALID002'), testValueAmountCode),
    [UB04_FIELDS.value41AAmount]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(numericDecimal, t('common:fieldErrors.VALID001', { validation: numericDecimalVal }))
      .min(2, t('common:fieldErrors.LEN001', { length: '(2-20)' }))
      .max(20, t('common:fieldErrors.LEN001', { length: '(2-20)' }))
      .test('custom-value41AAmount', t('common:fieldErrors.VALID002'), testValueAmountCode),
    [UB04_FIELDS.value41BAmount]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(numericDecimal, t('common:fieldErrors.VALID001', { validation: numericDecimalVal }))
      .min(2, t('common:fieldErrors.LEN001', { length: '(2-20)' }))
      .max(20, t('common:fieldErrors.LEN001', { length: '(2-20)' }))
      .test('custom-value41BAmount', t('common:fieldErrors.VALID002'), testValueAmountCode),
    [UB04_FIELDS.value41CAmount]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(numericDecimal, t('common:fieldErrors.VALID001', { validation: numericDecimalVal }))
      .min(2, t('common:fieldErrors.LEN001', { length: '(2-20)' }))
      .max(20, t('common:fieldErrors.LEN001', { length: '(2-20)' }))
      .test('custom-value41CAmount', t('common:fieldErrors.VALID002'), testValueAmountCode),
    [UB04_FIELDS.value41DAmount]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(numericDecimal, t('common:fieldErrors.VALID001', { validation: numericDecimalVal }))
      .min(2, t('common:fieldErrors.LEN001', { length: '(2-20)' }))
      .max(20, t('common:fieldErrors.LEN001', { length: '(2-20)' }))
      .test('custom-value41DAmount', t('common:fieldErrors.VALID002'), testValueAmountCode),

    [UB04_FIELDS.authorizationCode63a]: genericValidation(t, 1, 30, alphaNum, alphaNumVal),

    [UB04_FIELDS.documentControlNumber64a]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      // genericValidation(t, 12, 12, alphaNum, alphaNumVal)
      .test(
        'custom-documentControlNumber64aPattern',
        t('common:fieldErrors.RES002'),
        testDocumentControlNumber64aPattern
      )
      .when([UB04_FIELDS.payer50A, UB04_FIELDS.payer50B, UB04_FIELDS.payer50C], {
        is: (payerA: string, payerB: string, payerC: string) => {
          if (getDestinationPayer(payerA, payerB, payerC) === 'A') return true;
          return false;
        },
        then: (schema) =>
          genericValidation(t, 12, 12, alphaNum, alphaNumVal).test(
            'custom-documentControlNumber64aRequired',
            t('common:fieldErrors.TOB001'),
            testDocumentControlNumber64aRequired
          )
      }),

    [UB04_FIELDS.employerName65a]: genericValidation(t, 1, 35, alphaNumSpecChar, alphaNumSpecCharVal),

    [UB04_FIELDS.authorizationCode63b]: genericValidation(t, 1, 30, alphaNum, alphaNumVal),

    [UB04_FIELDS.documentControlNumber64b]: genericValidation(t, 1, 26, alphaNum, alphaNumVal).when(
      [UB04_FIELDS.payer50A, UB04_FIELDS.payer50B, UB04_FIELDS.payer50C],
      {
        is: (payerA: string, payerB: string, payerC: string) => {
          if (getDestinationPayer(payerA, payerB, payerC) === 'B') return true;
          return false;
        },
        then: (schema) =>
          genericValidation(t, 12, 12, alphaNum, alphaNumVal)
            .test(
              'custom-documentControlNumber64aRequired',
              t('common:fieldErrors.TOB001'),
              testDocumentControlNumber64aRequired
            )
            .test(
              'custom-documentControlNumber64bPattern',
              t('common:fieldErrors.RES002'),
              testDocumentControlNumber64bPattern
            )
      }
    ),

    [UB04_FIELDS.employerName65b]: genericValidation(t, 1, 35, alphaNumSpecChar, alphaNumSpecCharVal),

    [UB04_FIELDS.authorizationCode63c]: genericValidation(t, 1, 30, alphaNum, alphaNumVal),

    [UB04_FIELDS.documentControlNumber64c]: genericValidation(t, 1, 26, alphaNum, alphaNumVal).when(
      [UB04_FIELDS.payer50A, UB04_FIELDS.payer50B, UB04_FIELDS.payer50C],
      {
        is: (payerA: string, payerB: string, payerC: string) => {
          if (getDestinationPayer(payerA, payerB, payerC) === 'C') return true;
          return false;
        },
        then: (schema) =>
          genericValidation(t, 12, 12, alphaNum, alphaNumVal)
            .test(
              'custom-documentControlNumber64aRequired',
              t('common:fieldErrors.TOB001'),
              testDocumentControlNumber64aRequired
            )
            .test(
              'custom-documentControlNumber64cPattern',
              t('common:fieldErrors.RES002'),
              testDocumentControlNumber64cPattern
            )
      }
    ),

    [UB04_FIELDS.employerName65c]: genericValidation(t, 1, 35, alphaNumSpecChar, alphaNumSpecCharVal),

    [UB04_FIELDS.principalDiagnoseDx]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .required(t('common:fieldErrors.MUST001'))
      .matches(alphaNumWithDec, t('common:fieldErrors.VALID001', { validation: alphaNumWithDecVal }))
      .min(3, t('common:fieldErrors.LEN001', { length: '(3-7)' }))
      .max(7, t('common:fieldErrors.LEN001', { length: '(3-7)' }))
      .test('custom-code671', t('common:fieldErrors.LEN001', { length: '(3-7)' }), testICDValueZeroMin)
      .test('custom-code672', t('common:fieldErrors.LEN001', { length: '(3-7)' }), testICDValueZeroMax)
      .test('custom-code673', t('common:fieldErrors.ICD001'), testICDValueNineMin)
      .test('custom-code674', t('common:fieldErrors.ICD001'), testICDValueNineMax),
    // .test('dxCode-lookup', diagnoseCodeLookupMsg, diagnosisCodeLookup),
    [UB04_FIELDS.codeQualifier]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v)),
    [UB04_FIELDS.code67aDx]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(alphaNumWithDec, t('common:fieldErrors.VALID001', { validation: alphaNumWithDecVal }))
      .min(3, t('common:fieldErrors.LEN001', { length: '(3-7)' }))
      .max(7, t('common:fieldErrors.LEN001', { length: '(3-7)' }))
      .test('custom-code67aDx', t('common:fieldErrors.ICD001'), testCodeQualifier),
    [UB04_FIELDS.billing_addr2]: genericValidation(t, 1, 80, alphaNumSpecChar, alphaNumSpecCharVal),
    [UB04_FIELDS.pay_to_addr2]: genericValidation(t, 1, 80, alphaNumSpecChar, alphaNumSpecCharVal),
    [UB04_FIELDS.attending_provider_addr2]: genericValidation(t, 1, 55, alphaNumSpecChar, alphaNumSpecCharVal),

    [UB04_FIELDS.admittingDiagnosisCode]: genericValidation(t, 1, 10, alphaNumWithDec, alphaNumWithDecVal)
      .test('custom-admittingDiagnosisCode1', t('common:fieldErrors.ICD001'), testICDValueNineMin)
      .test('custom-admittingDiagnosisCode', t('common:fieldErrors.ICD001'), testICDValueNineMax)
      .test('custom-admissionHour', t('common:fieldErrors.TOB004'), testTypeOfBillTOB004),
    [UB04_FIELDS.ub68A]: genericValidation(t, 1, 10, alphaNum, alphaNumVal),
    [UB04_FIELDS.ub68B]: genericValidation(t, 1, 10, alphaNum, alphaNumVal),
    [UB04_FIELDS.reasonForVisitA]: genericValidation(t, 1, 8, alphaNum, alphaNumVal).test(
      'custom-reasonForVisitA',
      t('common:fieldErrors.mandatoryForTOB006'),
      testTypeOfBillTOB006
    ),
    [UB04_FIELDS.reasonForVisitB]: genericValidation(t, 1, 8, alphaNum, alphaNumVal),
    // .test(
    //   'custom-reasonForVisitB',
    //   t('common:fieldErrors.TOB006'),
    //   testTypeOfBillTOB006
    // ),
    [UB04_FIELDS.reasonForVisitC]: genericValidation(t, 1, 8, alphaNum, alphaNumVal),
    // .test(
    //   'custom-reasonForVisitC',
    //   t('common:fieldErrors.TOB006'),
    //   testTypeOfBillTOB006
    // ),
    [UB04_FIELDS.pps]: genericValidation(t, 1, 10, alphaNum, alphaNumVal),

    [UB04_FIELDS.codeEci72a]: genericValidation(t, 1, 10, alphaNum, alphaNumVal).matches(
      startsWithVWXY,
      t('common:fieldErrors.VALID006')
    ),
    [UB04_FIELDS.codeEci72b]: genericValidation(t, 1, 10, alphaNum, alphaNumVal).matches(
      startsWithVWXY,
      t('common:fieldErrors.VALID006')
    ),
    [UB04_FIELDS.codeEci72c]: genericValidation(t, 1, 10, alphaNum, alphaNumVal).matches(
      startsWithVWXY,
      t('common:fieldErrors.VALID006')
    ),
    [UB04_FIELDS.ub75]: genericValidation(t, 1, 10, alphaNum, alphaNumVal),
    [UB04_FIELDS.atten_Phy_Suf_01]: genericValidation(t, 1, 25, alpha, alphaVal),
    [UB04_FIELDS.oper_Phy_Suf_01]: genericValidation(t, 1, 20, alpha, alphaVal),
    [UB04_FIELDS.oth_Phy_Suf_01]: genericValidation(t, 1, 25, alpha, alphaVal),
    [UB04_FIELDS.oth_Phy_Suf_02]: genericValidation(t, 1, 20, alpha, alphaVal),
    [UB04_FIELDS.principalDiagnosePoa]: poaValidationFixLeng(t, 1, poaRegex, poaVal),
    [UB04_FIELDS.codeEci72aPoa]: genericValidation(t, 1, 10, alphaNum, alphaNumVal),
    [UB04_FIELDS.codeEci72bPoa]: genericValidation(t, 1, 10, alphaNum, alphaNumVal),
    [UB04_FIELDS.codeEci72cPoa]: genericValidation(t, 1, 10, alphaNum, alphaNumVal),

    [UB04_FIELDS.additionalCodeQualA]: genericValidationFixLeng(t, 2, alphaNum, alphaNumVal),
    [UB04_FIELDS.additionalCodeA]: genericValidation(t, 1, 13, alphaNum, alphaNumVal),
    [UB04_FIELDS.additionalCodeValA]: genericValidation(t, 1, 16, alphaNum, alphaNumVal),

    [UB04_FIELDS.additionalCodeQualB]: genericValidationFixLeng(t, 2, alphaNum, alphaNumVal),
    [UB04_FIELDS.additionalCodeB]: genericValidation(t, 1, 13, alphaNum, alphaNumVal),
    [UB04_FIELDS.additionalCodeValB]: genericValidation(t, 1, 16, alphaNum, alphaNumVal),

    [UB04_FIELDS.additionalCodeQualC]: genericValidationFixLeng(t, 2, alphaNum, alphaNumVal),
    [UB04_FIELDS.additionalCodeC]: genericValidation(t, 1, 13, alphaNum, alphaNumVal),
    [UB04_FIELDS.additionalCodeValC]: genericValidation(t, 1, 16, alphaNum, alphaNumVal),

    [UB04_FIELDS.additionalCodeQualD]: genericValidationFixLeng(t, 2, alphaNum, alphaNumVal),
    [UB04_FIELDS.additionalCodeD]: genericValidation(t, 1, 13, alphaNum, alphaNumVal),
    [UB04_FIELDS.additionalCodeValD]: genericValidation(t, 1, 16, alphaNum, alphaNumVal),

    [UB04_FIELDS.remarks]: genericValidation(t, 1, 80, alphaNumSpecCharTextarea, alphaNumSpecCharVal),

    ...getDiagnosisCodes(t)
  });

type GetYupValidator = (t: TFunction, fieldName: string, values?: any) => Rule;
export const getYupValidator: GetYupValidator = (t, fieldName, values) => {
  return {
    validator: async (_, value: StoreValue) => {
      await getUb04Schema(t, getFormProvidersDataFromGlobalObject()).validateAt(
        fieldName,
        { ...getFormProvidersDataFromGlobalObject(), [fieldName]: value },
        // @ts-ignore
        { context: { path: _.field } }
      );
    }
  };
};

export const getYupWarning: GetYupValidator = (t, fieldName) => {
  return {
    validator: async (_, value: StoreValue) => {
      if (knownWarnings.includes(fieldName))
        getUb04WarningSchema(t).validateSyncAt(
          fieldName,
          { ...getFormProvidersDataFromGlobalObject(), [fieldName]: value }, // @ts-ignore
          { context: { path: _.field } }
        );
    },
    warningOnly: true
  };
};

export const getYupWarningServiceTable: GetYupValidator = (t, fieldName) => {
  return {
    validator: async (_, value: StoreValue) => {
      getUb04WarningServiceTableShape(t).validateSyncAt(
        fieldName,
        { ...getFormProvidersDataFromGlobalObject(), [fieldName]: value }, // @ts-ignore
        { context: { path: _.field } }
      );
    },
    warningOnly: true
  };
};

type GetYupValidatorServiceTable = (t: TFunction, fieldName: string) => Rule;
export const getYupValidatorServiceTable: GetYupValidatorServiceTable = (t, fieldName) => {
  return {
    validator: async (_, value: StoreValue) => {
      const schema = getUb04ServiceTableShape(t);

      await schema.validateAt(
        fieldName,
        { ...getFormProvidersDataFromGlobalObject(), [fieldName]: value },
        // @ts-ignore
        { context: { path: _.field } }
      );
    }
  };
};
